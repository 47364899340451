import CurbsideLandingScreen from '@assets/MWebScreens/CurbsideLandingScreen.png';
import WalkUpToggleLandingScreen from '@assets/MWebScreens/WalkUpToggleLandingScreen.png';
import OnlineOrdersAndAdvDelivery from '@assets/MWebScreens/OnlineOrdersAndAdvDelivery.png';
import AccountMyData from '@assets/MWebScreens/AccountMyData.png';
import MyAccountScreen from '@assets/MWebScreens/MyAccountScreen.png';
import AccountProfileScreen from '@assets/MWebScreens/AccountProfileScreen.png';
import AccountPaymentScreen from '@assets/MWebScreens/AccountPaymentScreen.png';
import AccountPastOrdersScreen from '@assets/MWebScreens/AccountPastOrdersScreen.png';
import AccountLoyaltyScreen from '@assets/MWebScreens/AccountLoyaltyScreen.png';
import AccountAddressBook from '@assets/MWebScreens/AccountAddressBook.png';
import OptInScreen from '@assets/MWebScreens/OptInScreen.png';
import OrderScreen from '@assets/MWebScreens/OrderScreen.png';
import UpsellScreen from '@assets/MWebScreens/UpsellScreen.png';
import Badge from '@assets/MWebScreens/Badge.png';
import CheckoutScreen from '@assets/MWebScreens/CheckoutScreen.png';
import LoginScreen from '@assets/MWebScreens/LoginScreen.png';
import OrderDetailsScreen from '@assets/MWebScreens/OrderDetailsScreen.png';
import CartScreen from '@assets/MWebScreens/CartScreen.png';
import TipScreen from '@assets/MWebScreens/TipScreen.png';
import PaymentGiftCard from '@assets/MWebScreens/PaymentGiftCard.png';
import PaymentFullScreen from '@assets/MWebScreens/PaymentFullScreen.png';
import PaymentCouponScreen from '@assets/MWebScreens/PaymentCouponScreen.png';
import ItemDetailsScreen from '@assets/MWebScreens/ItemDetailsScreen.png';
import ReceiptScreen from '@assets/MWebScreens/ReceiptScreen.png';
import SurveyScreen from '@assets/MWebScreens/SurveyScreen.png';
import OrderSummaryScreen from '@assets/MWebScreens/OrderSummaryScreen.png';
import PaymentOptinScreen from '@assets/MWebScreens/PaymentOptinScreen.png';
import PaymentOptionsScreen from '@assets/MWebScreens/PaymentOptionsScreen.png';
import PaymentLoyaltyScreen from '@assets/MWebScreens/PaymentLoyaltyScreen.png';
import TextToCheckInLandingScreen from '@assets/MWebScreens/TextToCheckInLandingScreen.png';
import MyCheckScreen from '@assets/MWebScreens/MyCheckScreen.png';
import RefillScreen from '@assets/MWebScreens/RefillScreen.png';
import SplitEvenlyScreen from '@assets/MWebScreens/SplitEvenlyScreen.png';
import OrderConfirmationScreen from '@assets/MWebScreens/OrderConfirmationScreen.png';
import TextToCheckInConfirmationScreen from '@assets/MWebScreens/TextToCheckInConfirmationScreen.png';
import CheckNumberLandingScreen from '@assets/MWebScreens/CheckNumberLandingScreen.png';
import HostGuestLandingScreen from '@assets/MWebScreens/HostGuestLandingScreen.png';
import TableOrderPaymentOptionsScreen from '@assets/MWebScreens/TableOrderPaymentOptionsScreen.png';
import CheckSelectionScreen from '@assets/MWebScreens/CheckSelectionScreen.png';
import CheckPaidScreen from '@assets/MWebScreens/CheckPaidScreen.png';
import TableReassignment from '@assets/MWebScreens/TableReassignment.png';
import LoyaltyWalletScreen from '@assets/MWebScreens/LoyaltyWalletScreen.png';
import StoreLocatorLandingScreen from '@assets/MWebScreens/StoreLocatorLandingScreen.png';
import StoreListingScreen from '@assets/MWebScreens/StoreListingScreen.png';


export default {
  CurbsideLandingScreen: {
    screenLabel: 'Curbside - Landing Screen',
    image: [CurbsideLandingScreen],
    customLabels: [
      {
        defaultText: 'Spot Number',
        key: 'label.CLS_SPOT_NUMBER',
        type: 'label',
        value: 'CLS_SPOT_NUMBER',
      },
      {
        defaultText: 'Next',
        key: 'label.CLS_NEXT',
        type: 'label',
        value: 'CLS_NEXT',
      },
      {
        defaultText: 'Orders are currently taking',
        key: 'customMessage.CLS_ORDER_COMPLETE_MSG',
        type: 'customMessage',
        value: 'CLS_ORDER_COMPLETE_MSG',
      },
      {
        defaultText: 'Minutes',
        key: 'customMessage.CLS_MINUTES',
        type: 'customMessage',
        value: 'CLS_MINUTES',
      },
      {
        defaultText: 'Please enter your spot number',
        key: 'customMessage.CLS_ERR_SPOT_NUMBER',
        type: 'customMessage',
        value: 'CLS_ERR_SPOT_NUMBER',
      },
    ]
  },
  WalkUpScreen: {
    screenLabel: 'Walkup - Landing Screen',
    image: [WalkUpToggleLandingScreen],
    customLabels: [
      {
        defaultText: 'Dine In',
        key: 'label.WLS_DINE_IN',
        type: 'label',
        value: 'WLS_DINE_IN',
      },
      {
        defaultText: 'Takeout',
        key: 'label.WLS_TAKE_OUT',
        type: 'label',
        value: 'WLS_TAKE_OUT',
      },
      {
        defaultText: 'Name',
        key: 'label.WLS_NAME',
        type: 'label',
        value: 'WLS_NAME',
      },
      {
        defaultText: 'Orders are currently taking',
        key: 'customMessage.WLS_ORDER_COMPLETE_MSG',
        type: 'customMessage',
        value: 'WLS_ORDER_COMPLETE_MSG',
      },
      {
        defaultText: 'Minutes',
        key: 'customMessage.WLS_MINUTES',
        type: 'customMessage',
        value: 'WLS_MINUTES',
      },
      {
        defaultText: 'Next',
        key: 'label.WLS_NEXT',
        type: 'label',
        value: 'WLS_NEXT',
      },
      {
        defaultText: 'Name is required',
        key: 'customMessage.WLS_ERR_NAME_REQUIRED',
        type: 'customMessage',
        value: 'WLS_ERR_NAME_REQUIRED',
      },
    ]
  },
  OnlineOrdersAndAdvDelivery: {
    screenLabel: 'Online Orders and Advance Delivery - Landing Screen',
    image: [OnlineOrdersAndAdvDelivery],
    customLabels: [
      {
        defaultText: 'Pick up',
        key: 'label.OLS_PICK_UP',
        type: 'label',
        value: 'OLS_PICK_UP',
      },
      {
        defaultText: 'Date & Time',
        key: 'label.OLS_DATE_TIME_INPUT_LABEL',
        type: 'label',
        value: 'OLS_DATE_TIME_INPUT_LABEL',
      },
      {
        defaultText: 'Vehicle Description (Optional)',
        key: 'label.OLS_PICKUP_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'OLS_PICKUP_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Select Pickup Date',
        key: 'label.OLS_SELECT_PICK_UP_DATE',
        type: 'label',
        value: 'OLS_SELECT_PICK_UP_DATE',
      },
      {
        defaultText: 'Select Pickup Time',
        key: 'label.OLS_SELECT_PICK_UP_TIME',
        type: 'label',
        value: 'OLS_SELECT_PICK_UP_TIME',
      },
      {
        defaultText: 'Next',
        key: 'label.OLS_NEXT',
        type: 'label',
        value: 'OLS_NEXT',
      },
      // {
      //   defaultText: 'Schedule',
      //   key: 'label.OLS_SCHEDULE',
      //   type: 'label',
      //   value: 'OLS_SCHEDULE',
      // },
      {
        defaultText: 'Apply',
        key: 'label.OLS_APPLY',
        type: 'label',
        value: 'OLS_APPLY',
      },
      {
        defaultText: 'Cancel',
        key: 'label.OLS_CANCEL',
        type: 'label',
        value: 'OLS_CANCEL',
      },
      {
        defaultText: 'Delivery',
        key: 'label.OLS_DELIVERY',
        type: 'label',
        value: 'OLS_DELIVERY',
      },
      {
        defaultText: 'Delivery Address',
        key: 'label.OLS_DELIVERY_ADDRESS',
        type: 'label',
        value: 'OLS_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Apartment suite (Optional)',
        key: 'label.OLS_APARTMENT_SUITE_INPUT_LABEL',
        type: 'label',
        value: 'OLS_APARTMENT_SUITE_INPUT_LABEL',
      },
      {
        defaultText: 'Select Delivery Date',
        key: 'label.OLS_SELECT_DELIVERY_DATE',
        type: 'label',
        value: 'OLS_SELECT_DELIVERY_DATE',
      },
      {
        defaultText: 'Select Delivery Time',
        key: 'label.OLS_SELECT_DELIVERY_TIME',
        type: 'label',
        value: 'OLS_SELECT_DELIVERY_TIME',
      },
      {
        defaultText: 'Please select an address',
        key: 'label.OLS_PLEASE_SELECT_AN_ADDRESS',
        type: 'label',
        value: 'OLS_PLEASE_SELECT_AN_ADDRESS',
      },
      {
        defaultText: 'Done',
        key: 'label.OLS_DELIVERY_ADDRESS_DONE',
        type: 'label',
        value: 'OLS_DELIVERY_ADDRESS_DONE',
      },
      {
        defaultText: 'Delivery fee',
        key: 'customMessage.OLS_DELIVERY_FEE',
        type: 'customMessage',
        value: 'OLS_DELIVERY_FEE',
      },
      {
        defaultText: 'waived for orders above',
        key: 'customMessage.OLS_DELIVERY_WAIVED_ORDERS',
        type: 'customMessage',
        value: 'OLS_DELIVERY_WAIVED_ORDERS',
      },
      {
        defaultText: 'Minimum Order',
        key: 'customMessage.OLS_DELIVERY_MINIMUM_ORDER',
        type: 'customMessage',
        value: 'OLS_DELIVERY_MINIMUM_ORDER',
      },
      {
        defaultText: 'Please enter Date and Time',
        key: 'customMessage.OLS_ERR_ENTER_DATE_TIME',
        type: 'customMessage',
        value: 'OLS_ERR_ENTER_DATE_TIME',
      },
      // {
      //   defaultText: 'Please enter Date',
      //   key: 'customMessage.OLS_ERR_PICKUP_DATE',
      //   type: 'customMessage',
      //   value: 'OLS_ERR_PICKUP_DATE',
      // },
      // {
      //   defaultText: 'Select Time field is required',
      //   key: 'customMessage.OLS_ERR_DELIVERY_TIME',
      //   type: 'customMessage',
      //   value: 'OLS_ERR_DELIVERY_TIME',
      // },
      {
        defaultText: 'Delivery address is required',
        key: 'customMessage.OLS_ERR_DELIVERY_ADDRESS',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Please enter Date',
        key: 'customMessage.OLS_ERR_DELIVERY_DATE',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_DATE',
      },
      {
        defaultText: 'Select Time field is required',
        key: 'customMessage.OLS_ERR_DELIVERY_TIME',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_TIME',
      },
      {
        defaultText: 'Date and Time must be in the future',
        key: 'customMessage.OLS_ERR_DATE_TIME_FUTURE',
        type: 'customMessage',
        value: 'OLS_ERR_DATE_TIME_FUTURE',
      },
    ]
  },
  AccountMyData: {
    screenLabel: 'Account - My Data',
    image: [AccountMyData],
    customLabels: [

      {
        defaultText: 'Enter Mobile Number',
        key: 'label.AMD_ENTER_MOBILE_NUMBER',
        type: 'label',
        value: 'AMD_ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.AMD_EMAIL_ADDRESS',
        type: 'label',
        value: 'AMD_EMAIL_ADDRESS',
      },
      {
        defaultText: 'First Name',
        key: 'label.AMD_FIRST_NAME',
        type: 'label',
        value: 'AMD_FIRST_NAME',
      },
      {
        defaultText: 'Last Name',
        key: 'label.AMD_LAST_NAME',
        type: 'label',
        value: 'AMD_LAST_NAME',
      },
      {
        defaultText: 'Request data',
        key: 'label.AMD_REQUEST_DATA',
        type: 'label',
        value: 'AMD_REQUEST_DATA',
      },
      {
        defaultText: 'Delete data',
        key: 'label.AMD_DELETE_DATA',
        type: 'label',
        value: 'AMD_DELETE_DATA',
      },
      {
        defaultText: 'Comment',
        key: 'label.AMD_COMMENT',
        type: 'label',
        value: 'AMD_COMMENT',
      },
      {
        defaultText: 'Submit',
        key: 'label.AMD_SUBMIT',
        type: 'label',
        value: 'AMD_SUBMIT',
      },
      {
        defaultText: 'Confirmation',
        key: 'label.AMD_CONFIRMATION',
        type: 'label',
        value: 'AMD_CONFIRMATION',
      },
      {
        defaultText: 'Yes',
        key: 'label.AMD_YES',
        type: 'label',
        value: 'AMD_YES',
      },
      {
        defaultText: 'Cancel',
        key: 'label.AMD_CANCEL',
        type: 'label',
        value: 'AMD_CANCEL',
      },
      {
        defaultText: 'What would you like to do',
        key: 'customMessage.AMD_MY_DATA_ACTION_MESSAGE',
        type: 'customMessage',
        value: 'AMD_MY_DATA_ACTION_MESSAGE',
      },
      {
        defaultText: 'Are you sure you want to submit the form',
        key: 'customMessage.AMD_MY_DATA_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'AMD_MY_DATA_CONFIRMATION_MESSAGE',
      },
      {
        defaultText: 'Your Data',
        key: 'label.AMD_YOUR_DATA',
        type: 'label',
        value: 'AMD_YOUR_DATA',
      },
    ]
  },
  MyAccountScreen: {
    screenLabel: 'My Account Screen',
    image: [MyAccountScreen],
    customLabels: [
      {
        defaultText: 'My Account',
        key: 'label.AMA_MY_ACCOUNT',
        type: 'label',
        value: 'AMA_MY_ACCOUNT',
      },
      {
        defaultText: 'Profile',
        key: 'label.AMA_PROFILE',
        type: 'label',
        value: 'AMA_PROFILE',
      },
      {
        defaultText: 'Payments',
        key: 'label.AMA_PAYMENTS',
        type: 'label',
        value: 'AMA_PAYMENTS',
      },
      {
        defaultText: 'Loyalty Accounts',
        key: 'label.AMA_LOYALTY_ACCOUNTS',
        type: 'label',
        value: 'AMA_LOYALTY_ACCOUNTS',
      },
      {
        defaultText: 'Opt In',
        key: 'label.AMA_OPT_IN',
        type: 'label',
        value: 'AMA_OPT_IN',
      },
      {
        defaultText: 'Address Book',
        key: 'label.AMA_ADDRESS_BOOK',
        type: 'label',
        value: 'AMA_ADDRESS_BOOK',
      },
      {
        defaultText: 'Past Orders',
        key: 'label.AMA_PAST_ORDERS',
        type: 'label',
        value: 'AMA_PAST_ORDERS',
      },
      {
        defaultText: 'Sign Out',
        key: 'label.AMA_SIGN_OUT',
        type: 'label',
        value: 'AMA_SIGN_OUT',
      },
      {
        defaultText: 'Privacy Policy',
        key: 'label.AMA_PRIVACY_POLICY',
        type: 'label',
        value: 'AMA_PRIVACY_POLICY',
      },
      {
        defaultText: 'Terms and Conditions',
        key: 'label.AMA_TERMS_AND_CONDITION',
        type: 'label',
        value: 'AMA_TERMS_AND_CONDITION',
      },
      {
        defaultText: 'Cookies',
        key: 'label.AMA_COOKIES',
        type: 'label',
        value: 'AMA_COOKIES',
      },
      {
        defaultText: 'My Data',
        key: 'label.AMA_MY_DATA',
        type: 'label',
        value: 'AMA_MY_DATA',
      },
      {
        defaultText: 'Version',
        key: 'label.AMA_VERSION',
        type: 'label',
        value: 'AMA_VERSION',
      },
    ]
  },
  MyAccountProfileScreen: {
    screenLabel: 'Account - Profile Settings',
    image: [AccountProfileScreen],
    customLabels: [
      {
        defaultText: 'Profile Settings',
        key: 'label.APS_PROFILE_SETTINGS',
        type: 'label',
        value: 'APS_PROFILE_SETTINGS',
      },
      {
        defaultText: 'User Name',
        key: 'label.APS_USER_NAME',
        type: 'label',
        value: 'APS_USER_NAME',
      },
      {
        defaultText: 'First Name',
        key: 'label.APS_FIRST_NAME',
        type: 'label',
        value: 'APS_FIRST_NAME',
      },
      {
        defaultText: 'Last Name',
        key: 'label.APS_LAST_NAME',
        type: 'label',
        value: 'APS_LAST_NAME',
      },
      {
        defaultText: 'Order Nickname',
        key: 'label.APS_ORDER_NICKNAME',
        type: 'label',
        value: 'APS_ORDER_NICKNAME',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.APS_PHONE_NUMBER',
        type: 'label',
        value: 'APS_PHONE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.APS_EMAIL_ADDRESS',
        type: 'label',
        value: 'APS_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Save Changes',
        key: 'label.APS_SAVE_CHANGES',
        type: 'label',
        value: 'APS_SAVE_CHANGES',
      },
      {
        defaultText: 'Password reset',
        key: 'label.APS_PASSWORD_RESET',
        type: 'label',
        value: 'APS_PASSWORD_RESET',
      },
      {
        defaultText: 'Change Your Password',
        key: 'label.APS_CHANGE_PASSWORD',
        type: 'label',
        value: 'APS_CHANGE_PASSWORD',
      },
      {
        defaultText: 'Current Password',
        key: 'label.APS_CURRENT_PASSWORD',
        type: 'label',
        value: 'APS_CURRENT_PASSWORD',
      },
      {
        defaultText: 'New Password',
        key: 'label.APS_NEW_PASSWORD',
        type: 'label',
        value: 'APS_NEW_PASSWORD',
      },
      {
        defaultText: 'Confirm Password',
        key: 'label.APS_CONFIRM_PASSWORD',
        type: 'label',
        value: 'APS_CONFIRM_PASSWORD',
      },
      {
        defaultText: 'Your password must meet the following',
        key: 'customMessage.APS_PASSWORD_MUST_MEET_THE_FOLLOWING',
        type: 'customMessage',
        value: 'APS_PASSWORD_MUST_MEET_THE_FOLLOWING',
      },
      {
        defaultText: 'be 8 to 15 character long',
        key: 'customMessage.APS_EIGHT_CHARACTER_LONG',
        type: 'customMessage',
        value: 'APS_EIGHT_CHARACTER_LONG',
      },
      {
        defaultText: 'contain a mix of upper and lower case letters',
        key: 'customMessage.APS_HAVE_UPPER_LOWER_CASE',
        type: 'customMessage',
        value: 'APS_HAVE_UPPER_LOWER_CASE',
      },
      {
        defaultText: 'contain at least one number',
        key: 'customMessage.APS_AT_LEAST_ONE_NUMBER',
        type: 'customMessage',
        value: 'APS_AT_LEAST_ONE_NUMBER',
      },
      {
        defaultText: 'contain at least one special character',
        key: 'customMessage.APS_AT_LEAST_ONE_SPECIAL_CHARACTER',
        type: 'customMessage',
        value: 'APS_AT_LEAST_ONE_SPECIAL_CHARACTER',
      },
      {
        defaultText: 'First name is required',
        key: 'customMessage.APS_ERR_FIRST_NAME',
        type: 'customMessage',
        value: 'APS_ERR_FIRST_NAME',
      },
      {
        defaultText: 'Last name is required',
        key: 'customMessage.APS_ERR_LAST_NAME',
        type: 'customMessage',
        value: 'APS_ERR_LAST_NAME',
      },
      {
        defaultText: 'Email is required',
        key: 'customMessage.APS_ERR_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'APS_ERR_EMAIL_ADDRESS',
      },
      {
        defaultText: 'password is required',
        key: 'customMessage.APS_ERR_CURRENT_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_CURRENT_PASSWORD',
      },
      {
        defaultText: 'new password is required',
        key: 'customMessage.APS_ERR_NEW_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_NEW_PASSWORD',
      }, {
        defaultText: 'confirm password is required',
        key: 'customMessage.APS_ERR_CONFIRM_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_CONFIRM_PASSWORD',
      },
      {
        defaultText: 'Please enter a valid Email',
        key: 'customMessage.APS_ERR_VALID_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'APS_ERR_VALID_EMAIL_ADDRESS',
      },
      {
        defaultText: 'password & confirm password must be same',
        key: 'customMessage.APS_ERR_PASSWORD_CONFIRM_PASSWORD_SAME',
        type: 'customMessage',
        value: 'APS_ERR_PASSWORD_CONFIRM_PASSWORD_SAME',
      },
    ]
  },
  MyAccountPaymentScreen: {
    screenLabel: 'Account - Payment Screen',
    image: [AccountPaymentScreen],
    customLabels: [
      {
        defaultText: 'Manage payment methods',
        key: 'label.APM_PAYMENT_METHODS',
        type: 'label',
        value: 'APM_PAYMENT_METHODS',
      },
      {
        defaultText: 'Update Card Name',
        key: 'label.APM_UPDATE_NAME',
        type: 'label',
        value: 'APM_UPDATE_NAME',
      },
      {
        defaultText: 'Enter Card Name',
        key: 'label.APM_ENTER_CARD_NAME',
        type: 'label',
        value: 'APM_ENTER_CARD_NAME',
      },
      {
        defaultText: 'Save',
        key: 'label.APM_SAVE',
        type: 'label',
        value: 'APM_SAVE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.APM_CANCEL',
        type: 'label',
        value: 'APM_CANCEL',
      },
      {
        defaultText: 'No',
        key: 'label.APM_NO',
        type: 'label',
        value: 'APM_NO',
      },
      {
        defaultText: 'Delete Card',
        key: 'label.APM_DELETE_CARD',
        type: 'label',
        value: 'APM_DELETE_CARD',
      },
      {
        defaultText: 'Yes',
        key: 'label.APM_YES',
        type: 'label',
        value: 'APM_YES',
      },
      {
        defaultText: 'Are you sure you want to delete the card permanently. This action cannot be undone',
        key: 'customMessage.APM_PAYMENT_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'APM_PAYMENT_DELETE_MESSAGE',
      },
      {
        defaultText: 'Your wallet is empty',
        key: 'customMessage.APM_WALLETE_EMPTY',
        type: 'customMessage',
        value: 'APM_WALLETE_EMPTY',
      },
      {
        defaultText: 'Please enter the name',
        key: 'customMessage.APM_ERR_ENTER_NAME',
        type: 'customMessage',
        value: 'APM_ERR_ENTER_NAME',
      }
    ]
  },
  AccountAddressBookScreen: {
    screenLabel: 'Account - Address Book',
    image: [AccountAddressBook],
    customLabels: [
      {
        defaultText: 'Address Book',
        key: 'label.AAB_ADDRESS_BOOK',
        type: 'label',
        value: 'AAB_ADDRESS_BOOK',
      },
      {
        defaultText: 'Add Address',
        key: 'label.AAB_ADD_ADDRESS',
        type: 'label',
        value: 'AAB_ADD_ADDRESS',
      },
      {
        defaultText: 'Search Address',
        key: 'label.AAB_SEARCH_ADRESS',
        type: 'label',
        value: 'AAB_SEARCH_ADRESS',
      },
      {
        defaultText: 'Enter Name',
        key: 'label.AAB_ENTER_NAME',
        type: 'label',
        value: 'AAB_ENTER_NAME',
      },
      {
        defaultText: 'Cancel',
        key: 'label.AAB_CANCEL',
        type: 'label',
        value: 'AAB_CANCEL',
      },
      {
        defaultText: 'Save',
        key: 'label.AAB_SAVE',
        type: 'label',
        value: 'AAB_SAVE',
      },
      {
        defaultText: 'Edit Address',
        key: 'label.AAB_EDIT_ADDRESS',
        type: 'label',
        value: 'AAB_EDIT_ADDRESS',
      },
      {
        defaultText: 'Address',
        key: 'label.AAB_ADDRESS',
        type: 'label',
        value: 'AAB_ADDRESS',
      },
      {
        defaultText: 'Name',
        key: 'label.AAB_NAME',
        type: 'label',
        value: 'AAB_NAME',
      },
      {
        defaultText: 'Delete Address',
        key: 'label.AAB_DELETE_ADDRESS',
        type: 'label',
        value: 'AAB_DELETE_ADDRESS',
      },
      {
        defaultText: 'Delete',
        key: 'label.AAB_DELETE',
        type: 'label',
        value: 'AAB_DELETE',
      },
      {
        defaultText: 'Are you sure you want to delete the Address permanently. This action cannot be undone',
        key: 'customMessage.AAB_OPTIN_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'AAB_OPTIN_DELETE_MESSAGE',
      },
      {
        defaultText: 'No addresses found',
        key: 'customMessage.AAB_NO_ADDRESS_FOUND',
        type: 'customMessage',
        value: 'AAB_NO_ADDRESS_FOUND',
      },
    ]
  },
  AccountPastOrdersScreen: {
    screenLabel: 'Account - Past Orders Screen',
    image: [AccountPastOrdersScreen],
    customLabels: [
      {
        defaultText: 'Past Orders',
        key: 'label.APO_PAST_ORDERS',
        type: 'label',
        value: 'APO_PAST_ORDERS',
      },
      {
        defaultText: 'View Details',
        key: 'label.APO_VIEW_DETAILS',
        type: 'label',
        value: 'APO_VIEW_DETAILS',
      },
      {
        defaultText: 'Item Total',
        key: 'label.APO_ITEM_TOTAL',
        type: 'label',
        value: 'APO_ITEM_TOTAL',
      },
      {
        defaultText: 'Delivery fee',
        key: 'label.APO_DELIVERY_FEE',
        type: 'label',
        value: 'APO_DELIVERY_FEE',
      },
      {
        defaultText: 'Tip',
        key: 'label.APO_TIP',
        type: 'label',
        value: 'APO_TIP',
      },
      {
        defaultText: 'Tax & Restaurant Charges',
        key: 'label.APO_TAX_RESTAURANT_CHARGES',
        type: 'label',
        value: 'APO_TAX_RESTAURANT_CHARGES',
      },
      {
        defaultText: 'Total',
        key: 'label.APO_TOTAL',
        type: 'label',
        value: 'APO_TOTAL',
      },
      {
        defaultText: 'You haven\'t placed any orders yet',
        key: 'customMessage.APO_PAST_ORDERS_EMPTY',
        type: 'customMessage',
        value: 'APO_PAST_ORDERS_EMPTY',
      },
    ]
  },
  AccountLoyaltyScreen: {
    screenLabel: 'Account - Loyalty Screen',
    image: [AccountLoyaltyScreen],
    customLabels: [
      {
        defaultText: 'Loyalty Accounts',
        key: 'label.ALA_LOYALTY_ACCOUNTS',
        type: 'label',
        value: 'ALA_LOYALTY_ACCOUNTS',
      },
      {
        defaultText: 'Update Card Name',
        key: 'label.ALA_UPDATE_NAME',
        type: 'label',
        value: 'ALA_UPDATE_NAME',
      },
      {
        defaultText: 'Enter Card Name',
        key: 'label.ALA_ENTER_CARD_NAME',
        type: 'label',
        value: 'ALA_ENTER_CARD_NAME',
      },
      {
        defaultText: 'Save',
        key: 'label.ALA_SAVE',
        type: 'label',
        value: 'ALA_SAVE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.ALA_CANCEL',
        type: 'label',
        value: 'ALA_CANCEL',
      },
      {
        defaultText: 'Delete Card',
        key: 'label.ALA_DELETE_CARD',
        type: 'label',
        value: 'ALA_DELETE_CARD',
      },
      // {
      //   defaultText: 'Yes',
      //   key: 'label.ALA_YES',
      //   type: 'label',
      //   value: 'ALA_YES',
      // },
      // {
      //   defaultText: 'No',
      //   key: 'label.ALA_NO',
      //   type: 'label',
      //   value: 'ALA_NO',
      // },
      {
        defaultText: 'View Offers',
        key: 'label.ALA_VIEW_OFFERS',
        type: 'label',
        value: 'ALA_VIEW_OFFERS',
      },
      {
        defaultText: 'Confirm',
        key: 'label.ALA_CONFIRM',
        type: 'label',
        value: 'ALA_CONFIRM',
      },
      {
        defaultText: 'Offers available',
        key: 'label.ALA_OFFERS_AVAILABLE',
        type: 'label',
        value: 'ALA_OFFERS_AVAILABLE',
      },
      {
        defaultText: 'Are you sure you want to delete the card permanently. This action cannot be undone',
        key: 'customMessage.ALA_PAYMENT_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'ALA_PAYMENT_DELETE_MESSAGE',
      },
      {
        defaultText: 'No offers currently available',
        key: 'customMessage.ALA_NO_OFFERS_AVAILABLE',
        type: 'customMessage',
        value: 'ALA_NO_OFFERS_AVAILABLE',
      },
      {
        defaultText: 'Please enter the name',
        key: 'customMessage.ALA_ERR_ENTER_NAME',
        type: 'customMessage',
        value: 'ALA_ERR_ENTER_NAME',
      },
    ]
  },
  AccountOptInScreen: {
    screenLabel: 'Account - Opt In Screen',
    image: [OptInScreen],
    customLabels: [
      {
        defaultText: 'Opt In',
        key: 'label.AOI_OPT_IN',
        type: 'label',
        value: 'AOI_OPT_IN',
      },
      {
        defaultText: 'Opt in to receive communication',
        key: 'label.AOI_RECIVE_COMMUNICATION',
        type: 'label',
        value: 'AOI_RECIVE_COMMUNICATION',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.AOI_PHONE_NUMBER',
        type: 'label',
        value: 'AOI_PHONE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.AOI_EMAIL_ADDRESS',
        type: 'label',
        value: 'AOI_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Save',
        key: 'label.AOI_SAVE',
        type: 'label',
        value: 'AOI_SAVE',
      },
      {
        defaultText: 'Please enter valid phone number',
        key: 'customMessage.AOI_ERR_PHONE_NUMBER',
        type: 'customMessage',
        value: 'AOI_ERR_PHONE_NUMBER',
      },
      {
        defaultText: 'Please enter valid email address',
        key: 'customMessage.AOI_ERR_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'AOI_ERR_EMAIL_ADDRESS',
      },
    ]
  },
  AccountOrderScreen: {
    screenLabel: 'Orders Screen',
    image: [OrderScreen],
    customLabels: [
      {
        defaultText: 'Search',
        key: 'label.ORS_SEARCH',
        type: 'label',
        value: 'ORS_SEARCH',
      },
      {
        defaultText: 'View',
        key: 'label.ORS_VIEW',
        type: 'label',
        value: 'ORS_VIEW',
      },
      {
        defaultText: 'ORDER',
        key: 'label.ORS_ORDER',
        type: 'label',
        value: 'ORS_ORDER',
      },
      {
        defaultText: 'more',
        key: 'label.ORS_MORE',
        type: 'label',
        value: 'ORS_MORE',
      },
      {
        defaultText: 'CAL',
        key: 'label.ORS_CAL',
        type: 'label',
        value: 'ORS_CAL',
      },
      {
        defaultText: 'No',
        key: 'label.ORS_NO',
        type: 'label',
        value: 'ORS_NO',
      },
      {
        defaultText: 'YES',
        key: 'label.ORS_YES',
        type: 'label',
        value: 'ORS_YES',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.ORS_ORDER_DELETE',
        type: 'customMessage',
        value: 'ORS_ORDER_DELETE',
      },
    ]
  },
  UpsellScreen: {
    screenLabel: 'Upsell Screen',
    image: [UpsellScreen],
    customLabels: [
      {
        defaultText: 'Order',
        key: 'label.USS_ORDER',
        type: 'label',
        value: 'USS_ORDER',
      },
      {
        defaultText: 'Skip',
        key: 'label.USS_SKIP',
        type: 'label',
        value: 'USS_SKIP',
      },
      {
        defaultText: 'Continue',
        key: 'label.USS_CONTINUE',
        type: 'label',
        value: 'USS_CONTINUE',
      },
      // {
      //   defaultText: 'Yes',
      //   key: 'label.USS_YES',
      //   type: 'label',
      //   value: 'USS_YES',
      // },
      // {
      //   defaultText: 'No',
      //   key: 'label.USS_NO',
      //   type: 'label',
      //   value: 'USS_NO',
      // },
      {
        defaultText: 'You May Also Like?',
        key: 'customMessage.USS_UPSELL_TITLE',
        type: 'customMessage',
        value: 'USS_UPSELL_TITLE',
      },
      // {
      //   defaultText: 'Are you sure, you want to delete this item?',
      //   key: 'customMessage.USS_UPSELL_DELETE',
      //   type: 'customMessage',
      //   value: 'USS_UPSELL_DELETE',
      // },
    ]
  },
  Badge: {
    screenLabel: 'Badge',
    image: [Badge],
    customLabels: [
      {
        defaultText: 'Out of Stock',
        key: 'label.BDG_OUT_OF_STOCK',
        type: 'label',
        value: 'BDG_OUT_OF_STOCK',
      },
      {
        defaultText: 'View Only',
        key: 'label.BDG_VIEW_ONLY',
        type: 'label',
        value: 'BDG_VIEW_ONLY',
      },
      {
        defaultText: 'New Item',
        key: 'label.BDG_NEW_ITEM',
        type: 'label',
        value: 'BDG_NEW_ITEM',
      },
      {
        defaultText: 'Limited Time',
        key: 'label.BDG_LIMITED_TIME',
        type: 'label',
        value: 'BDG_LIMITED_TIME',
      },
      {
        defaultText: 'Featured',
        key: 'label.BDG_FEATURED',
        type: 'label',
        value: 'BDG_FEATURED',
      },
      {
        defaultText: 'Special',
        key: 'label.BDG_SPECIAL',
        type: 'label',
        value: 'BDG_SPECIAL',
      },
      {
        defaultText: 'Coming Soon',
        key: 'label.BDG_COMING_SOON',
        type: 'label',
        value: 'BDG_COMING_SOON',
      },
      {
        defaultText: 'Limited Quantity',
        key: 'label.BDG_LIMITED_QUANTITY',
        type: 'label',
        value: 'BDG_LIMITED_QUANTITY',
      },
      {
        defaultText: 'Additional Time',
        key: 'label.BDG_ADDITIONAL_TIME',
        type: 'label',
        value: 'BDG_ADDITIONAL_TIME',
      },
      {
        defaultText: 'Time Restricted',
        key: 'label.BDG_TIME_RESTRICTED',
        type: 'label',
        value: 'BDG_TIME_RESTRICTED',
      },
    ]
  },
  CheckoutScreen: {
    screenLabel: 'CheckoutScreen',
    image: [CheckoutScreen],
    customLabels: [
      {
        defaultText: 'Your Cart',
        key: 'label.OCS_YOUR_CART',
        type: 'label',
        value: 'OCS_YOUR_CART',
      },
      {
        defaultText: 'ITEMS',
        key: 'label.OCS_ITEMS',
        type: 'label',
        value: 'OCS_ITEMS',
      },
      {
        defaultText: 'Edit',
        key: 'label.OCS_EDIT',
        type: 'label',
        value: 'OCS_EDIT',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.OCS_SUBTOTAL',
        type: 'label',
        value: 'OCS_SUBTOTAL',
      },
      {
        defaultText: 'Check out',
        key: 'label.OCS_CHECKOUT',
        type: 'label',
        value: 'OCS_CHECKOUT',
      },
      {
        defaultText: 'Yes',
        key: 'label.OCS_YES',
        type: 'label',
        value: 'OCS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.OCS_NO',
        type: 'label',
        value: 'OCS_NO',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.OCS_DELETE_ITEM',
        type: 'customMessage',
        value: 'OCS_DELETE_ITEM',
      },
    ]
  },
  AddCardPage: {
    screenLabel: 'Add Card page ',
    customLabels: [
      {
        defaultText: 'Street Address',
        key: 'label.HL_STREET_ADDRESS',
        type: 'label',
        value: 'HL_STREET_ADDRESS',
      },
      {
        defaultText: 'Street Address',
        key: 'label.HL_STREET_ADDRESS_PLACEHOLDER',
        type: 'label',
        value: 'HL_STREET_ADDRESS_PLACEHOLDER',
      },
    ]
  },
  LoginScreen: {
    screenLabel: 'Login Screen',
    image: [LoginScreen],
    customLabels: [
      {
        defaultText: 'Verify',
        key: 'label.LS_VERIFY',
        type: 'label',
        value: 'LS_VERIFY',
      },
      {
        defaultText: 'Loading',
        key: 'label.LS_LOADING',
        type: 'label',
        value: 'LS_LOADING',
      },
      {
        defaultText: 'Continue as Guest',
        key: 'label.LS_CONTINUE_AS_GUEST',
        type: 'label',
        value: 'LS_CONTINUE_AS_GUEST',
      },
      {
        defaultText: 'Verify Your Phone',
        key: 'label.LS_VERIFY_YOUR_PHONE',
        type: 'label',
        value: 'LS_VERIFY_YOUR_PHONE',
      },
      {
        defaultText: 'Username',
        key: 'label.LS_USERNAME',
        type: 'label',
        value: 'LS_USERNAME',
      },
      {
        defaultText: 'Password',
        key: 'label.LS_ENTER_PASSWORD',
        type: 'label',
        value: 'LS_ENTER_PASSWORD',
      },
      {
        defaultText: 'Sign In',
        key: 'label.LS_LOGIN',
        type: 'label',
        value: 'LS_LOGIN',
      },
      {
        defaultText: 'Reset Password',
        key: 'label.LS_RESET_PASSWORD',
        type: 'label',
        value: 'LS_RESET_PASSWORD',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.LS_PHONE_NUMBER',
        type: 'label',
        value: 'LS_PHONE_NUMBER',
      },
      {
        defaultText: 'Confirm Password',
        key: 'label.LS_CONFIRM_PASSWORD',
        type: 'label',
        value: 'LS_CONFIRM_PASSWORD',
      },
      {
        defaultText: 'Enter Mobile Number',
        key: 'label.LS_ENTER_MOBILE_NUMBER',
        type: 'label',
        value: 'LS_ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Sign in with your phone number',
        key: 'label.LS_SIGN_IN_WITH_YOUR_PHONE_NUMBER',
        type: 'label',
        value: 'LS_SIGN_IN_WITH_YOUR_PHONE_NUMBER',
      },
      {
        defaultText: 'Sign in with a password',
        key: 'label.LS_SIGN_IN_WITH_PASSWORD',
        type: 'label',
        value: 'LS_SIGN_IN_WITH_PASSWORD',
      },
      {
        defaultText: 'Sign in to your account',
        key: 'label.LS_SIGN_IN_TO_YOUR_ACCOUNT',
        type: 'label',
        value: 'LS_SIGN_IN_TO_YOUR_ACCOUNT',
      },
      {
        defaultText: 'Forgot your password?',
        key: 'label.LS_FORGOT_YOUR_PASSWORD',
        type: 'label',
        value: 'LS_FORGOT_YOUR_PASSWORD',
      },
      {
        defaultText: 'Reset your password',
        key: 'label.LS_RESET_YOUR_PASSWORD',
        type: 'label',
        value: 'LS_RESET_YOUR_PASSWORD',
      },
      {
        defaultText: 'Remember your Password?',
        key: 'label.LS_REMEMBER_YOUR_PASSWORD',
        type: 'label',
        value: 'LS_REMEMBER_YOUR_PASSWORD',
      },
      {
        defaultText: 'Code',
        key: 'label.LS_RESET_CODE',
        type: 'label',
        value: 'LS_RESET_CODE',
      },
      {
        defaultText: 'Sending',
        key: 'label.LS_SENDING_OTP',
        type: 'label',
        value: 'LS_SENDING_OTP',
      },
      {
        defaultText: 'You`ll need to verify your phone number before you can order or pay.',
        key: 'customMessage.LS_VERIFY_PHONE_NUMBER_MSG',
        type: 'customMessage',
        value: 'LS_VERIFY_PHONE_NUMBER_MSG',
      },
      {
        defaultText: 'You`re checking in at:',
        key: 'customMessage.LS_YOUR_CHECKED_LOCATION',
        type: 'customMessage',
        value: 'LS_YOUR_CHECKED_LOCATION',
      },
      {
        defaultText: 'By entering your mobile number you agree to receive a one time password and order status alerts via text message. Message and data rates may apply.',
        key: 'customMessage.LS_STANDARD_TEXT_RATES',
        type: 'customMessage',
        value: 'LS_STANDARD_TEXT_RATES',
      },
      {
        defaultText: 'Your password must meet the following:',
        key: 'customMessage.LS_YOUR_PASSWORD_MUST_MEET_THE_FOLLOWING',
        type: 'customMessage',
        value: 'LS_YOUR_PASSWORD_MUST_MEET_THE_FOLLOWING',
      },
      {
        defaultText: 'be 8 to 15 characters long',
        key: 'customMessage.LS_EIGHT_CHARACTERS_LONG',
        type: 'customMessage',
        value: 'LS_EIGHT_CHARACTERS_LONG',
      },
      {
        defaultText: 'contain a mix of upper and lower case letters',
        key: 'customMessage.LS_HAVE_BOTH_UPPER_AND_LOWER_CASE',
        type: 'customMessage',
        value: 'LS_HAVE_BOTH_UPPER_AND_LOWER_CASE',
      },
      {
        defaultText: 'contain at least one number',
        key: 'customMessage.LS_AT_LEAST_ONE_NUMBER',
        type: 'customMessage',
        value: 'LS_AT_LEAST_ONE_NUMBER',
      },
      {
        defaultText: 'contain at least one special character',
        key: 'customMessage.LS_AT_LEAST_ONE_SPECIAL_CHARACTER',
        type: 'customMessage',
        value: 'LS_AT_LEAST_ONE_SPECIAL_CHARACTER',
      },
      {
        defaultText: 'code is required',
        key: 'customMessage.LS_ERR_RESET_CODE_IS_REQUIRED',
        type: 'customMessage',
        value: 'LS_ERR_RESET_CODE_IS_REQUIRED',
      },
      {
        defaultText: 'new password is required',
        key: 'customMessage.LS_ERR_RESET_NEW_PASSWORD_IS_REQUIRED',
        type: 'customMessage',
        value: 'LS_ERR_RESET_NEW_PASSWORD_IS_REQUIRED',
      },
      {
        defaultText: 'confirm password is required',
        key: 'customMessage.LS_ERR_RESET_CONFIRM_PASSWORD_IS_REQUIRED',
        type: 'customMessage',
        value: 'LS_ERR_RESET_CONFIRM_PASSWORD_IS_REQUIRED',
      },
      {
        defaultText: 'Invalid Mobile Number',
        key: 'customMessage.LS_ERR_INVALID_MOBILE_NUMBER',
        type: 'customMessage',
        value: 'LS_ERR_INVALID_MOBILE_NUMBER',
      },
      {
        defaultText: 'Please enter your username.',
        key: 'customMessage.LS_ERR_PLEASE_ENTER_YOUR_USERNAME',
        type: 'customMessage',
        value: 'LS_ERR_PLEASE_ENTER_YOUR_USERNAME',
      },
      {
        defaultText: 'Please enter your password.',
        key: 'customMessage.LS_ERR_PLEASE_ENTER_YOUR_PASSWORD',
        type: 'customMessage',
        value: 'LS_ERR_PLEASE_ENTER_YOUR_PASSWORD',
      },
      {
        defaultText: 'Invalid Country Code',
        key: 'customMessage.LS_ERR_INVALID_COUNTRY_CODE',
        type: 'customMessage',
        value: 'LS_ERR_INVALID_COUNTRY_CODE',
      },
      {
        defaultText: 'Please enter Country Code',
        key: 'customMessage.LS_ERR_REQ_COUNTRY_CODE',
        type: 'customMessage',
        value: 'LS_ERR_REQ_COUNTRY_CODE',
      },
      {
        defaultText: 'invalid password (please refer to the notes below)',
        key: 'customMessage.LS_ERR_RESET_PASSWORD_SCREEN_INVALID_PASSWORD_REFER',
        type: 'customMessage',
        value: 'LS_ERR_RESET_PASSWORD_SCREEN_INVALID_PASSWORD_REFER',
      },
    ]
  },
  OrderDetailsScreen: {
    screenLabel: 'Order Details Screen',
    image: [OrderDetailsScreen],
    customLabels: [
      {
        defaultText: 'Order Details',
        key: 'label.ODS_ORDER_DETAILS',
        type: 'label',
        value: 'ODS_ORDER_DETAILS',
      },
      {
        defaultText: 'Enter your Order details',
        key: 'label.ODS_ENTER_ORDER_DETAILS',
        type: 'label',
        value: 'ODS_ENTER_ORDER_DETAILS',
      },
      {
        defaultText: 'Spot Number',
        key: 'label.ODS_SPOT_NUMBER',
        type: 'label',
        value: 'ODS_SPOT_NUMBER',
      },
      {
        defaultText: 'Enter Your Name',
        key: 'label.ODS_NAME',
        type: 'label',
        value: 'ODS_NAME',
      },
      {
        defaultText: 'Add order notes (Optional)',
        key: 'label.ODS_ADD_ORDER_NOTES',
        type: 'label',
        value: 'ODS_ADD_ORDER_NOTES',
      },
      {
        defaultText: 'Pick up',
        key: 'label.ODS_PICK_UP',
        type: 'label',
        value: 'ODS_PICK_UP',
      },
      {
        defaultText: 'Delivery',
        key: 'label.ODS_DELIVERY',
        type: 'label',
        value: 'ODS_DELIVERY',
      },
      {
        defaultText: 'Date & Time',
        key: 'label.ODS_DATE_TIME',
        type: 'label',
        value: 'ODS_DATE_TIME',
      },
      {
        defaultText: 'Vehicle Description',
        key: 'label.ODS_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'ODS_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Add Utensils?',
        key: 'label.ODS_ADD_UTENSILS',
        type: 'label',
        value: 'ODS_ADD_UTENSILS',
      },
      {
        defaultText: 'Delivery Address',
        key: 'label.ODS_DELIVERY_ADDRESS',
        type: 'label',
        value: 'ODS_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Apartment Suite (Optional)',
        key: 'label.ODS_APARTMENT_SUITE',
        type: 'label',
        value: 'ODS_APARTMENT_SUITE',
      },
      {
        defaultText: 'Confirm',
        key: 'label.ODS_CONFIRM',
        type: 'label',
        value: 'ODS_CONFIRM',
      },
      {
        defaultText: 'Select Delivery Date',
        key: 'label.ODS_SELECT_DELIVERY_DATE',
        type: 'label',
        value: 'ODS_SELECT_DELIVERY_DATE',
      },
      {
        defaultText: 'Select Delivery Time',
        key: 'label.ODS_SELECT_DELIVERY_TIME',
        type: 'label',
        value: 'ODS_SELECT_DELIVERY_TIME',
      },
      {
        defaultText: 'Select Pickup Date',
        key: 'label.ODS_SELECT_PICKUP_DATE',
        type: 'label',
        value: 'ODS_SELECT_PICKUP_DATE',
      },
      {
        defaultText: 'Select Pickup Time',
        key: 'label.ODS_SELECT_PICKUP_TIME',
        type: 'label',
        value: 'ODS_SELECT_PICKUP_TIME',
      },
      {
        defaultText: 'Apply',
        key: 'label.ODS_APPLY',
        type: 'label',
        value: 'ODS_APPLY',
      },
      {
        defaultText: 'Cancel',
        key: 'label.ODS_CANCEL',
        type: 'label',
        value: 'ODS_CANCEL',
      },
      {
        defaultText: 'Vehicle Type',
        key: 'label.ODS_VEHICLE_TYPE',
        type: 'label',
        value: 'ODS_VEHICLE_TYPE',
      },
      {
        defaultText: 'Vehicle Color',
        key: 'label.ODS_VEHICLE_COLOR',
        type: 'label',
        value: 'ODS_VEHICLE_COLOR',
      },
      {
        defaultText: 'Select Vehicle Type',
        key: 'label.ODS_SELECT_TYPE',
        type: 'label',
        value: 'ODS_SELECT_TYPE',
      },
      {
        defaultText: 'Select Vehicle Color',
        key: 'label.ODS_SELECT_COLOR',
        type: 'label',
        value: 'ODS_SELECT_COLOR',
      },
      {
        defaultText:
          'Confirm the Order details to review the cart items and calculate the subtotal.',
        key: 'customMessage.ODS_CONFIRM_ORDER_DEATILS',
        type: 'customMessage',
        value: 'ODS_CONFIRM_ORDER_DEATILS',
      },
      {
        defaultText: 'Please enter Date and Time',
        key: 'customMessage.ODS_ERR_ENTER_DATE_TIME',
        type: 'customMessage',
        value: 'ODS_ERR_ENTER_DATE_TIME',
      },
      {
        defaultText: 'Please enter Date',
        key: 'customMessage.ODS_ERR_PICKUP_DELIVERY_DATE',
        type: 'customMessage',
        value: 'ODS_ERR_PICKUP_DELIVERY_DATE',
      },
      {
        defaultText: 'Delivery address is required',
        key: 'customMessage.ODS_ERR_DELIVERY_ADDRESS',
        type: 'customMessage',
        value: 'ODS_ERR_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Please enter your spot number',
        key: 'customMessage.ODS_ERR_SPOT_NUMBER',
        type: 'customMessage',
        value: 'ODS_ERR_SPOT_NUMBER',
      },
      {
        defaultText: 'Date and time must be in future',
        key: 'customMessage.ODS_ERR_DATE_TIME_MUST_BE_FUTURE',
        type: 'customMessage',
        value: 'ODS_ERR_DATE_TIME_MUST_BE_FUTURE',
      },
    ],
  },
  CartScreen: {
    screenLabel: 'Cart Screen',
    image: [CartScreen],
    customLabels: [
      {
        defaultText: 'Bag',
        key: 'label.CS_BAG',
        type: 'label',
        value: 'CS_BAG',
      },
      {
        defaultText: 'Your Bag',
        key: 'label.CS_YOUR_BAG',
        type: 'label',
        value: 'CS_YOUR_BAG',
      },
      {
        defaultText: 'Your items',
        key: 'label.CS_YOUR_ITEMS',
        type: 'label',
        value: 'CS_YOUR_ITEMS',
      },
      {
        defaultText: 'Edit',
        key: 'label.CS_EDIT',
        type: 'label',
        value: 'CS_EDIT',
      },
      {
        defaultText: 'Add more items',
        key: 'label.CS_ADD_MORE_ITEMS',
        type: 'label',
        value: 'CS_ADD_MORE_ITEMS',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.CS_SUB_TOTAL',
        type: 'label',
        value: 'CS_SUB_TOTAL',
      },
      {
        defaultText: 'Add name',
        key: 'label.CS_ADD_NAME',
        type: 'label',
        value: 'CS_ADD_NAME',
      },
      {
        defaultText: 'Pay Now',
        key: 'label.CS_PAY_NOW',
        type: 'label',
        value: 'CS_PAY_NOW',
      },
      {
        defaultText: 'Order Now',
        key: 'label.CS_ORDER_NOW',
        type: 'label',
        value: 'CS_ORDER_NOW',
      },
      {
        defaultText: 'Yes',
        key: 'label.CS_YES',
        type: 'label',
        value: 'CS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.CS_NO',
        type: 'label',
        value: 'CS_NO',
      },
      {
        defaultText: 'Spot Number',
        key: 'label.CS_SPOT_NUMBER',
        type: 'label',
        value: 'CS_SPOT_NUMBER',
      },
      {
        defaultText: 'Order Name',
        key: 'label.CS_ORDER_NAME',
        type: 'label',
        value: 'CS_ORDER_NAME',
      },
      {
        defaultText: 'Change',
        key: 'label.CS_CHANGE',
        type: 'label',
        value: 'CS_CHANGE',
      },
      {
        defaultText: 'Order Notes',
        key: 'label.CS_ORDER_NOTES',
        type: 'label',
        value: 'CS_ORDER_NOTES',
      },
      {
        defaultText: 'Pickup Date & Time',
        key: 'label.CS_PICKUP_DATE_AND_TIME',
        type: 'label',
        value: 'CS_PICKUP_DATE_AND_TIME',
      },
      {
        defaultText: 'Vehicle Description',
        key: 'label.CS_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'CS_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Delivery Date & Time',
        key: 'label.CS_DELIVERY_DATE_AND_TIME',
        type: 'label',
        value: 'CS_DELIVERY_DATE_AND_TIME',
      },
      {
        defaultText: 'Delivery Address',
        key: 'label.CS_DELIVERY_ADDRESS',
        type: 'label',
        value: 'CS_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Apartment Suite',
        key: 'label.CS_APARTMENT_SUITE',
        type: 'label',
        value: 'CS_APARTMENT_SUITE',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.CS_DELETE_ITEM_CONFIRMATION',
        type: 'customMessage',
        value: 'CS_DELETE_ITEM_CONFIRMATION',
      },
      {
        defaultText: 'Your cart is empty.',
        key: 'customMessage.CS_YOUR_CART_EMPTY',
        type: 'customMessage',
        value: 'CS_YOUR_CART_EMPTY',
      },
      {
        defaultText: 'Go ahead and add something!',
        key: 'customMessage.CS_ADD_SOMETHING',
        type: 'customMessage',
        value: 'CS_ADD_SOMETHING',
      },
      {
        defaultText: 'Items',
        key: 'label.CS_ITEMS',
        type: 'label',
        value: 'CS_ITEMS',
      },
      {
        defaultText: 'My Cart',
        key: 'label.CS_MY_CART',
        type: 'label',
        value: 'CS_MY_CART',
      },
      {
        defaultText: 'Refill',
        key: 'label.RFS_REFILL',
        type: 'label',
        value: 'RFS_REFILL',
      },
      {
        defaultText: 'Keep it open',
        key: 'label.CS_KEEP_IT_OPEN',
        type: 'label',
        value: 'CS_KEEP_IT_OPEN',
      },
    ]
  },
  TipScreen: {
    screenLabel: 'Payment - Tip Screen',
    image: [TipScreen],
    customLabels: [
      {
        defaultText: 'Add a tip',
        key: 'label.PTS_ADD_A_TIP',
        type: 'label',
        value: 'PTS_ADD_A_TIP',
      },
      {
        defaultText: 'No Tip',
        key: 'label.PTS_NO_TIP',
        type: 'label',
        value: 'PTS_NO_TIP',
      },
      {
        defaultText: 'Other',
        key: 'label.PTS_OTHER',
        type: 'label',
        value: 'PTS_OTHER',
      },
      {
        defaultText: 'Add Tip',
        key: 'label.PTS_ADD_TIP',
        type: 'label',
        value: 'PTS_ADD_TIP',
      },
      {
        defaultText: 'Percent',
        key: 'label.PTS_PERCENT',
        type: 'label',
        value: 'PTS_PERCENT',
      },
      {
        defaultText: 'Amount',
        key: 'label.PTS_AMOUNT',
        type: 'label',
        value: 'PTS_AMOUNT',
      },
      {
        defaultText: 'Cancel',
        key: 'label.PTS_CANCEL',
        type: 'label',
        value: 'PTS_CANCEL',
      },
      {
        defaultText: 'ADD TIP',
        key: 'label.PTS_ADD_TIP_BTN',
        type: 'label',
        value: 'PTS_ADD_TIP_BTN',
      },
      {
        defaultText: 'A tip disclaimer on your app or website is often the best way to address specific points of liability that could fall outside a Terms and Conditions agreement or a Privacy Policy agreement.',
        key: 'customMessage.PTS_TIP_DISCLAIMER',
        type: 'customMessage',
        value: 'PTS_TIP_DISCLAIMER',
      },
      {
        defaultText: 'Tip this percentage of the subtotal:',
        key: 'customMessage.PTS_TIPS_PERCENTAGE_MSG',
        type: 'customMessage',
        value: 'PTS_TIPS_PERCENTAGE_MSG',
      },
      {
        defaultText: 'Including tip, pay a total of:',
        key: 'customMessage.PTS_TOTAL_TIPS_MSG',
        type: 'customMessage',
        value: 'PTS_TOTAL_TIPS_MSG',
      },
      {
        defaultText: 'Tips this amount:',
        key: 'customMessage.PTS_TIP_AMOUNT_MSG',
        type: 'customMessage',
        value: 'PTS_TIP_AMOUNT_MSG',
      },
      {
        defaultText: 'enter a valid percent or zero',
        key: 'customMessage.PTS_ERR_TIP_INVALID_PERCETAGE_AMOUNT',
        type: 'customMessage',
        value: 'PTS_ERR_TIP_INVALID_PERCETAGE_AMOUNT',
      },
      {
        defaultText: 'enter a valid amount or zero',
        key: 'customMessage.PTS_ERR_TIP_INVALID__AMOUNT',
        type: 'customMessage',
        value: 'PTS_ERR_TIP_INVALID__AMOUNT',
      },
      {
        defaultText: 'Please enter a valid amount',
        key: 'customMessage.PTS_ERR_TIP_AMOUNT',
        type: 'customMessage',
        value: 'PTS_ERR_TIP_AMOUNT',
      },
      {
        defaultText: 'Please enter a valid percent',
        key: 'customMessage.PTS_ERR_TIP_PERCENT',
        type: 'customMessage',
        value: 'PTS_ERR_TIP_PERCENT',
      },
    ]
  },
  PaymentGiftCard: {
    screenLabel: 'PAYMENT - GIFT CARD',
    image: [PaymentGiftCard],
    customLabels: [
      {
        defaultText: 'Gift card',
        key: 'label.PGC_GIFT_CARD',
        type: 'label',
        value: 'PGC_GIFT_CARD',
      },
      {
        defaultText: 'Add a gift card',
        key: 'label.PGC_ADD_GIFT_CARD',
        type: 'label',
        value: 'PGC_ADD_GIFT_CARD',
      },
      {
        defaultText: 'Check balance',
        key: 'label.PGC_BALANCE',
        type: 'label',
        value: 'PGC_BALANCE',
      },
      {
        defaultText: 'Balance Amount:',
        key: 'label.PGC_BALANCE_AMT',
        type: 'label',
        value: 'PGC_BALANCE_AMT',
      },
      {
        defaultText: 'Redeemed Amount: ',
        key: 'label.PGC_REDEEM_AMT',
        type: 'label',
        value: 'PGC_REDEEM_AMT',
      },
      {
        defaultText: 'Skip Gift Card',
        key: 'label.PGC_SKIP_GIFTCARD',
        type: 'label',
        value: 'PGC_SKIP_GIFTCARD',
      },
      {
        defaultText: 'Yes',
        key: 'label.PGC_YES',
        type: 'label',
        value: 'PGC_YES',
      },
      {
        defaultText: 'No',
        key: 'label.PGC_NO',
        type: 'label',
        value: 'PGC_NO',
      },
      {
        defaultText: 'Apply Gift Card',
        key: 'label.PGC_APPLY_GIFTCARD',
        type: 'label',
        value: 'PGC_APPLY_GIFTCARD',
      },
      {
        defaultText: 'Gift Card Number:',
        key: 'label.PGC_CARD_NUMBER',
        type: 'label',
        value: 'PGC_CARD_NUMBER',
      },
      {
        defaultText: 'Enter gift card number',
        key: 'label.PGC_NUMBER',
        type: 'label',
        value: 'PGC_NUMBER',
      },
      {
        defaultText: 'Enter security code',
        key: 'label.PGC_SECURITY_CODE',
        type: 'label',
        value: 'PGC_SECURITY_CODE',
      },
      {
        defaultText: 'Are you sure, You want to delete gift card?',
        key: 'customMessage.PGC_DELETE',
        type: 'customMessage',
        value: 'PGC_DELETE',
      },
    ]
  },
  PaymentFullScreen: {
    screenLabel: 'Payment - Full screen ',
    image: [PaymentFullScreen],
    customLabels: [
      {
        defaultText: 'Payment',
        key: 'label.PS_PAYMENT',
        type: 'label',
        value: 'PS_PAYMENT',
      },
      {
        defaultText: 'Sub Total',
        key: 'label.PS_SUB_TOTAL',
        type: 'label',
        value: 'PS_SUB_TOTAL',
      },
      {
        defaultText: 'Tax',
        key: 'label.PS_TAX',
        type: 'label',
        value: 'PS_TAX',
      },
      {
        defaultText: 'Total Due',
        key: 'label.PS_TOTAL_DUE',
        type: 'label',
        value: 'PS_TOTAL_DUE',
      },
      {
        defaultText: 'Tip',
        key: 'label.PS_SUBTOTAL_TIP',
        type: 'label',
        value: 'PS_SUBTOTAL_TIP',
      },
      {
        defaultText: 'Offers',
        key: 'label.PS_SUBTOTAL_OFFERS',
        type: 'label',
        value: 'PS_SUBTOTAL_OFFERS',
      },
      {
        defaultText: 'Gift card',
        key: 'label.PS_SUBTOTAL_GIFTCARD',
        type: 'label',
        value: 'PS_SUBTOTAL_GIFTCARD',
      },
      {
        defaultText: 'Your cart',
        key: 'label.PS_YOUR_CART',
        type: 'label',
        value: 'PS_YOUR_CART',
      },
      {
        defaultText: 'Change',
        key: 'label.PS_CHANGE',
        type: 'label',
        value: 'PS_CHANGE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.PS_CANCEL',
        type: 'label',
        value: 'PS_CANCEL',
      },
      {
        defaultText: 'Confirm',
        key: 'label.PS_CONFIRM',
        type: 'label',
        value: 'PS_CONFIRM',
      },
      {
        defaultText: 'Your order is being processed and may take a moment. Please do not close this window or click the back or refresh button on your browser.',
        key: 'customMessage.PS_ORDER_LOADING_MESSAGE',
        type: 'customMessage',
        value: 'PS_ORDER_LOADING_MESSAGE',
      },
      {
        defaultText: 'If you proceed, your card will be charged.',
        key: 'customMessage.PS_PAYMENT_CONFIRMATION',
        type: 'customMessage',
        value: 'PS_PAYMENT_CONFIRMATION',
      },
      {
        defaultText: 'Please select a tip to complete payment',
        key: 'customMessage.PS_ERR_TIP_SELECTION_REQUIRED_MESSAGE',
        type: 'customMessage',
        value: 'PS_ERR_TIP_SELECTION_REQUIRED_MESSAGE',
      },
      {
        defaultText: 'Items',
        key: 'label.PS_ITEMS',
        type: 'label',
        value: 'PS_ITEMS',
      },
      {
        defaultText: 'OK',
        key: 'label.PS_OK',
        type: 'label',
        value: 'PS_OK',
      },
      {
        defaultText: 'Delivery fee',
        key: 'label.PS_DELIVERY_FEE',
        type: 'label',
        value: 'PS_DELIVERY_FEE',
      },
    ]
  },
  PaymentCouponScreen: {
    screenLabel: 'Payment - Coupon Screen',
    image: [PaymentCouponScreen],
    customLabels: [
      {
        defaultText: 'Coupon',
        key: 'label.PCS_COUPON',
        type: 'label',
        value: 'PCS_COUPON',
      },
      {
        defaultText: 'Apply coupon',
        key: 'label.PCS_COUPON_APPLY',
        type: 'label',
        value: 'PCS_COUPON_APPLY',
      },
      {
        defaultText: 'Enter coupon number',
        key: 'label.PCS_ENTER_COUPON_NUMBER',
        type: 'label',
        value: 'PCS_ENTER_COUPON_NUMBER',
      },
      {
        defaultText: 'Apply',
        key: 'label.PCS_APPLY_COUPON',
        type: 'label',
        value: 'PCS_APPLY_COUPON',
      },
      {
        defaultText: 'Cancel',
        key: 'label.PCS_CANCEL',
        type: 'label',
        value: 'PCS_CANCEL',
      },
      {
        defaultText: 'Delete',
        key: 'label.PCS_CONFIRM',
        type: 'label',
        value: 'PCS_CONFIRM',
      },
      {
        defaultText: 'Are you sure, You want to delete Coupon?',
        key: 'customMessage.PCS_COUPON_DELETE',
        type: 'customMessage',
        value: 'PCS_COUPON_DELETE',
      },
      {
        defaultText: 'Coupon added Successfully',
        key: 'customMessage.PCS_ADD_COUPON_SUCCESS',
        type: 'customMessage',
        value: 'PCS_ADD_COUPON_SUCCESS',
      },
      {
        defaultText: 'Coupon removed Successfully',
        key: 'customMessage.PCS_REMOVE_COUPON_SUCCESS',
        type: 'customMessage',
        value: 'PCS_REMOVE_COUPON_SUCCESS',
      },
      {
        defaultText: 'Please enter coupon number',
        key: 'customMessage.PCS_ERR_ENTER_COUPON_NUMBER',
        type: 'customMessage',
        value: 'PCS_ERR_ENTER_COUPON_NUMBER',
      },
    ]
  },
  // AccountLoyaltyScreenScreen: {
  //   screenLabel: 'Account - Loyalty Screen',
  //   image: [AccountLoyalty],
  //   customLabels: [
  //     {
  //       defaultText: 'Loyalty Accounts',
  //       key: 'label.ALA_LOYALTY_ACCOUNTS',
  //       type: 'label',
  //       value: 'ALA_LOYALTY_ACCOUNTS',
  //     },
  //     {
  //       defaultText: 'Update Card Name',
  //       key: 'label.ALA_UPDATE_NAME',
  //       type: 'label',
  //       value: 'ALA_UPDATE_NAME',
  //     },
  //     {
  //       defaultText: 'Enter Card Name',
  //       key: 'label.ALA_ENTER_CARD_NAME',
  //       type: 'label',
  //       value: 'ALA_ENTER_CARD_NAME',
  //     },
  //     {
  //       defaultText: 'Save',
  //       key: 'label.ALA_SAVE',
  //       type: 'label',
  //       value: 'ALA_SAVE',
  //     },
  //     {
  //       defaultText: 'Cancel',
  //       key: 'label.ALA_CANCEL',
  //       type: 'label',
  //       value: 'ALA_CANCEL',
  //     },
  //     {
  //       defaultText: 'Delete Card',
  //       key: 'label.ALA_DELETE_CARD',
  //       type: 'label',
  //       value: 'ALA_DELETE_CARD',
  //     },
  //     {
  //       defaultText: 'Yes',
  //       key: 'label.ALA_YES',
  //       type: 'label',
  //       value: 'ALA_YES',
  //     },
  //     {
  //       defaultText: 'No',
  //       key: 'label.ALA_NO',
  //       type: 'label',
  //       value: 'ALA_NO',
  //     },
  //     {
  //       defaultText: 'Are you sure you want to delete the card permanently. This action cannot be undone',
  //       key: 'customMessage.ALA_PAYMENT_DELETE_MESSAGE',
  //       type: 'customMessage',
  //       value: 'ALA_PAYMENT_DELETE_MESSAGE',
  //     },
  //     {
  //       defaultText: 'No offers currently available',
  //       key: 'customMessage.ALA_NO_OFFERS_AVAILABLE',
  //       type: 'customMessage',
  //       value: 'ALA_NO_OFFERS_AVAILABLE',
  //     },
  //     {
  //       defaultText: 'Please enter the name',
  //       key: 'customMessage.ALA_ERR_ENTER_NAME',
  //       type: 'customMessage',
  //       value: 'ALA_ERR_ENTER_NAME',
  //     },
  //   ]
  // },
  ItemDetailsScreen: {
    screenLabel: 'Item Details Screen ',
    image: [ItemDetailsScreen],
    customLabels: [
      {
        defaultText: 'Cal',
        key: 'label.IDS_CAL',
        type: 'label',
        value: 'IDS_CAL',
      },
      {
        defaultText: 'Show more',
        key: 'label.IDS_SHOW_MORE',
        type: 'label',
        value: 'IDS_SHOW_MORE',
      },
      {
        defaultText: 'Show less',
        key: 'label.IDS_SHOW_LESS',
        type: 'label',
        value: 'IDS_SHOW_LESS',
      },
      {
        defaultText: 'Nutrition Facts',
        key: 'label.IDS_NUTRITION_FACT',
        type: 'label',
        value: 'IDS_NUTRITION_FACT',
      },
      {
        defaultText: 'Total',
        key: 'label.IDS_TOTAL',
        type: 'label',
        value: 'IDS_TOTAL',
      },
      {
        defaultText: 'Yes',
        key: 'label.IDS_YES',
        type: 'label',
        value: 'IDS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.IDS_NO',
        type: 'label',
        value: 'IDS_NO',
      },
      {
        defaultText: 'Update Bag',
        key: 'label.IDS_UPDATE_CART',
        type: 'label',
        value: 'IDS_UPDATE_CART',
      },
      {
        defaultText: 'Add to Bag',
        key: 'label.IDS_ADD_TO_CART',
        type: 'label',
        value: 'IDS_ADD_TO_CART',
      },
      {
        defaultText: 'Servings size',
        key: 'label.IDS_SERVING_SIZE',
        type: 'label',
        value: 'IDS_SERVING_SIZE',
      },
      {
        defaultText: 'Calories',
        key: 'label.IDS_CALORIES',
        type: 'label',
        value: 'IDS_CALORIES',
      },
      {
        defaultText: 'Total Fat',
        key: 'label.IDS_TOTAL_FAT',
        type: 'label',
        value: 'IDS_TOTAL_FAT',
      },
      {
        defaultText: 'Saturated Fat',
        key: 'label.IDS_SATURATED_FAT',
        type: 'label',
        value: 'IDS_SATURATED_FAT',
      },
      {
        defaultText: 'Trans Fat',
        key: 'label.IDS_TRANS_FAT',
        type: 'label',
        value: 'IDS_TRANS_FAT',
      },
      {
        defaultText: 'Cholesterol',
        key: 'label.IDS_CHOLESTROL',
        type: 'label',
        value: 'IDS_CHOLESTROL',
      },
      {
        defaultText: 'Sodium',
        key: 'label.IDS_SODIUM',
        type: 'label',
        value: 'IDS_SODIUM',
      },
      {
        defaultText: 'Total Carbohydrate',
        key: 'label.IDS_TOTAL_CARBOHYDRATE',
        type: 'label',
        value: 'IDS_TOTAL_CARBOHYDRATE',
      },
      {
        defaultText: 'Dietary Fiber',
        key: 'label.IDS_DIETARY_FIBER',
        type: 'label',
        value: 'IDS_DIETARY_FIBER',
      },
      {
        defaultText: 'Total Sugars',
        key: 'label.IDS_TOTAL_SUGARS',
        type: 'label',
        value: 'IDS_TOTAL_SUGARS',
      },
      {
        defaultText: 'Includes',
        key: 'label.IDS_INCLUDES',
        type: 'label',
        value: 'IDS_INCLUDES',
      },
      {
        defaultText: 'Added Sugars',
        key: 'label.IDS_ADDED_SUGARS',
        type: 'label',
        value: 'IDS_ADDED_SUGARS',
      },
      {
        defaultText: 'Protein',
        key: 'label.IDS_PROTEIN',
        type: 'label',
        value: 'IDS_PROTEIN',
      },
      {
        defaultText: 'Vitamin D',
        key: 'label.IDS_VITAMIND',
        type: 'label',
        value: 'IDS_VITAMIND',
      },
      {
        defaultText: 'Calcium',
        key: 'label.IDS_CALCIUM',
        type: 'label',
        value: 'IDS_CALCIUM',
      },
      {
        defaultText: 'Iron',
        key: 'label.IDS_IRON',
        type: 'label',
        value: 'IDS_IRON',
      },
      {
        defaultText: 'Potassium',
        key: 'label.IDS_POTASSIUM',
        type: 'label',
        value: 'IDS_POTASSIUM',
      },
      {
        defaultText: 'Select any (optional)',
        key: 'label.IDS_SELECT_ANY',
        type: 'label',
        value: 'IDS_SELECT_ANY',
      },
      {
        defaultText: 'Remove',
        key: 'label.IDS_REMOVE',
        type: 'label',
        value: 'IDS_REMOVE',
      },
      {
        defaultText: 'Done',
        key: 'label.IDS_DONE',
        type: 'label',
        value: 'IDS_DONE',
      },
      {
        defaultText: 'Skip',
        key: 'label.IDS_SKIP',
        type: 'label',
        value: 'IDS_SKIP',
      },
      {
        defaultText: 'Apply',
        key: 'label.IDS_APPLY',
        type: 'label',
        value: 'IDS_APPLY',
      },
      {
        defaultText: 'OK',
        key: 'label.IDS_OK',
        type: 'label',
        value: 'IDS_OK',
      },
      {
        defaultText: 'Add Special Instructions ({{remaining}} of {{total}} remaining) (eg: Add Special Instructions(15 0f 20 remaining)',
        key: 'label.IDS_SPECIAL_INSTRUCTIONS',
        type: 'label',
        value: 'IDS_SPECIAL_INSTRUCTIONS',
      },
      {
        defaultText: 'Select {{minValue}} to {{maxValue}} items (required) (eg : select 1 to 2 items(required))',
        key: 'label.IDS_SELECT_REQUIRED',
        type: 'label',
        value: 'IDS_SELECT_REQUIRED',
      },
      {
        defaultText: 'Select upto {{maxValue}} (optional) (eg : select upto 3 (optional))',
        key: 'label.IDS_SELECT_UPTO',
        type: 'label',
        value: 'IDS_SELECT_UPTO',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.IDS_DELETE_ITEM_CONFIRMATION',
        type: 'customMessage',
        value: 'IDS_DELETE_ITEM_CONFIRMATION',
      },
      {
        defaultText: 'Servings per container',
        key: 'customMessage.IDS_SERVING_PER_CONTAINER',
        type: 'customMessage',
        value: 'IDS_SERVING_PER_CONTAINER',
      },
      {
        defaultText: 'Amount per serving',
        key: 'customMessage.IDS_AMOUNT_PER_SERVING',
        type: 'customMessage',
        value: 'IDS_AMOUNT_PER_SERVING',
      },
      {
        defaultText: '% Daily Value *',
        key: 'customMessage.IDS_DAILY_VALUE',
        type: 'customMessage',
        value: 'IDS_DAILY_VALUE',
      },
      {
        defaultText: '* The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.',
        key: 'customMessage.IDS_DAILY_VALUE_NOTE',
        type: 'customMessage',
        value: 'IDS_DAILY_VALUE_NOTE',
      },
      {
        defaultText: 'Who are you ordering this for?',
        key: 'customMessage.IDS_ORDER_NICK_NAME',
        type: 'customMessage',
        value: 'IDS_ORDER_NICK_NAME',
      },
      {
        defaultText: 'Must be ordered {{time}} {{hours}} before (eg: Must be ordered 5 hours before)',
        key: 'customMessage.IDS_MUST_BE_ORDERED',
        type: 'customMessage',
        value: 'IDS_MUST_BE_ORDERED',
      },
      {
        defaultText: 'Quantity restrictions: Max- {{maxValue}}, Min-{{minValue}} (eg: Max-10 , Min-5)',
        key: 'customMessage.IDS_QUANTITY_RESTRICTIONS',
        type: 'customMessage',
        value: 'IDS_QUANTITY_RESTRICTIONS',
      },
      {
        defaultText: 'Available {{startTime}} to {{endTime}} (eg: Available 09:00 a.m. to 11:00 p.m)',
        key: 'customMessage.IDS_AVAILABLE_TIME',
        type: 'customMessage',
        value: 'IDS_AVAILABLE_TIME',
      },
      {
        defaultText: 'Please select all the required modifiers',
        key: 'customMessage.IDS_ERR_SELECT_REQUIRED_MODIFIERS',
        type: 'customMessage',
        value: 'IDS_ERR_SELECT_REQUIRED_MODIFIERS',
      },
      {
        defaultText: 'Please enter the name',
        key: 'customMessage.IDS_ERR_SCREEN_ENTER_NAME',
        type: 'customMessage',
        value: 'IDS_ERR_SCREEN_ENTER_NAME',
      },
      {
        defaultText: 'Please select an option',
        key: 'customMessage.IDS_ERR_SELECT_LINKED_ITEMS',
        type: 'customMessage',
        value: 'IDS_ERR_SELECT_LINKED_ITEMS',
      },
    ]
  },
  ReceiptScreen: {
    screenLabel: 'Receipt Screen',
    image: [ReceiptScreen],
    customLabels: [
      {
        defaultText: 'Deliver to',
        key: 'label.RS_DELIVER_TO',
        type: 'label',
        value: 'RS_DELIVER_TO',
      },
      {
        defaultText: 'Qty',
        key: 'label.RS_QTY',
        type: 'label',
        value: 'RS_QTY',
      },
      {
        defaultText: 'Item(s)',
        key: 'label.RS_ITEM',
        type: 'label',
        value: 'RS_ITEM',
      },
      {
        defaultText: 'Amount',
        key: 'label.RS_AMOUNT',
        type: 'label',
        value: 'RS_AMOUNT',
      },
      {
        defaultText: 'Sub Total',
        key: 'label.RS_SUBTOTAL',
        type: 'label',
        value: 'RS_SUBTOTAL',
      },
      {
        defaultText: 'Tip',
        key: 'label.RS_TIP',
        type: 'label',
        value: 'RS_TIP',
      },
      {
        defaultText: 'Tax',
        key: 'label.RS_TAX',
        type: 'label',
        value: 'RS_TAX',
      },
      {
        defaultText: 'Total',
        key: 'label.RS_TOTAL',
        type: 'label',
        value: 'RS_TOTAL',
      },
      {
        defaultText: 'Paid with PayPal',
        key: 'label.RS_PAID_WITH_PAYPAL',
        type: 'label',
        value: 'RS_PAID_WITH_PAYPAL',
      },
      {
        defaultText: 'Transaction Id',
        key: 'label.RS_TXN_ID',
        type: 'label',
        value: 'RS_TXN_ID',
      },
      {
        defaultText: 'PayPal Email',
        key: 'label.RS_PAYPAL_EMAIL',
        type: 'label',
        value: 'RS_PAYPAL_EMAIL',
      },
      {
        defaultText: 'Pickup Date',
        key: 'label.RS_PICKUP_DATE',
        type: 'label',
        value: 'RS_PICKUP_DATE',
      },
      {
        defaultText: 'Pickup time',
        key: 'label.RS_PICKUP_TIME',
        type: 'label',
        value: 'RS_PICKUP_TIME',
      },
      {
        defaultText: 'Vehicle Description',
        key: 'label.RS_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'RS_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Delivery Time',
        key: 'label.RS_DELIVERY_TIME',
        type: 'label',
        value: 'RS_DELIVERY_TIME',
      },
      {
        defaultText: 'Delivery Date',
        key: 'label.RS_DELIVERY_DATE',
        type: 'label',
        value: 'RS_DELIVERY_DATE',
      },
      {
        defaultText: 'Delivery Address',
        key: 'label.RS_DELIVERY_ADDRESS',
        type: 'label',
        value: 'RS_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Delivery fee',
        key: 'label.RS_DELIVERY_FEE',
        type: 'label',
        value: 'RS_DELIVERY_FEE',
      },
      {
        defaultText: 'Apartment Suite',
        key: 'label.RS_APARTMENT_SUITE',
        type: 'label',
        value: 'RS_APARTMENT_SUITE',
      },
      {
        defaultText: 'Paid to {{cardType}} ending with {{cardNumber}}',
        key: 'customMessage.RS_PAID_TO_ENDING_WITH',
        type: 'customMessage',
        value: 'RS_PAID_TO_ENDING_WITH',
      },
    ]
  },
  SurveyScreen: {
    screenLabel: 'Survey Screen',
    image: [SurveyScreen],
    customLabels: [
      {
        defaultText: 'Skip Survey',
        key: 'label.SS_SKIP_SURVEY',
        type: 'label',
        value: 'SS_SKIP_SURVEY',
      },
      {
        defaultText: 'Take the survey',
        key: 'label.SS_SURVEY_CONTINUE',
        type: 'label',
        value: 'SS_SURVEY_CONTINUE',
      },
      {
        defaultText: 'Back',
        key: 'label.SS_BACK',
        type: 'label',
        value: 'SS_BACK',
      },
      {
        defaultText: 'Next',
        key: 'label.SS_NEXT',
        type: 'label',
        value: 'SS_NEXT',
      },
      {
        defaultText: 'Yes',
        key: 'label.SS_YES',
        type: 'label',
        value: 'SS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.SS_NO',
        type: 'label',
        value: 'SS_NO',
      },
      {
        defaultText: 'Submit',
        key: 'label.SS_SUBMIT',
        type: 'label',
        value: 'SS_SUBMIT',
      },
      {
        defaultText: 'By answering  this 3 minute survey, you help us improve our service for you',
        key: 'customMessage.SS_WELCOME_MESSAGE',
        type: 'customMessage',
        value: 'SS_WELCOME_MESSAGE',
      },
      {
        defaultText: 'May we contact you ?',
        key: 'customMessage.SS_CONTACT',
        type: 'customMessage',
        value: 'SS_CONTACT',
      },
      {
        defaultText: 'Tell us how to contact you',
        key: 'customMessage.SS_HOW_TO_CONTACT_YOU',
        type: 'customMessage',
        value: 'SS_HOW_TO_CONTACT_YOU',
      },
      {
        defaultText: 'Yes contact me by Email',
        key: 'customMessage.SS_CONTACT_BY_EMAIL',
        type: 'customMessage',
        value: 'SS_CONTACT_BY_EMAIL',
      },
      {
        defaultText: 'Yes contact me by phone call',
        key: 'customMessage.SS_CONTACT_BY_PHONE',
        type: 'customMessage',
        value: 'SS_CONTACT_BY_PHONE',
      },
      {
        defaultText: 'Thank you, Your feedback will help us improve our service',
        key: 'customMessage.SS_THANKS_MESSAGE',
        type: 'customMessage',
        value: 'SS_THANKS_MESSAGE',
      },
      // {
      //   defaultText: 'Thank you, survey has saved successfully',
      //   key: 'customMessage.SS_SUCCESS',
      //   type: 'customMessage',
      //   value: 'SS_SUCCESS',
      // },
      {
        defaultText: 'Email address is required',
        key: 'customMessage.SS_ERR_EMAIL_ADDRESS_REQUIRED',
        type: 'customMessage',
        value: 'SS_ERR_EMAIL_ADDRESS_REQUIRED',
      },
      {
        defaultText: 'Mobile number is required',
        key: 'customMessage.SS_ERR_MOBILE_NUMBER_REQUIRED',
        type: 'customMessage',
        value: 'SS_ERR_MOBILE_NUMBER_REQUIRED',
      },
      {
        defaultText: 'Invalid Email Address.',
        key: 'customMessage.SS_ERR_EMAIL_ADDRESS_INVALID',
        type: 'customMessage',
        value: 'SS_ERR_EMAIL_ADDRESS_INVALID',
      },
      {
        defaultText: 'Invalid Mobile Number.',
        key: 'customMessage.SS_ERR_IN_VALID_PHONE',
        type: 'customMessage',
        value: 'SS_ERR_IN_VALID_PHONE',
      },
      {
        defaultText: 'Please enter Country Code',
        key: 'customMessage.SS_ERR_REQ_COUNTRY_CODE',
        type: 'customMessage',
        value: 'SS_ERR_REQ_COUNTRY_CODE',
      },
      {
        defaultText: 'Invalid Country Code',
        key: 'customMessage.SS_ERR_INVALID_COUNTRY_CODE',
        type: 'customMessage',
        value: 'SS_ERR_INVALID_COUNTRY_CODE',
      },
    ]
  },
  OrderSummaryScreen: {
    screenLabel: 'Order Summary Screen',
    image: [OrderSummaryScreen],
    customLabels: [
      {
        defaultText: 'View directions',
        key: 'label.OSS_VIEW_DIRECTIONS',
        type: 'label',
        value: 'OSS_VIEW_DIRECTIONS',
      },
      {
        defaultText: 'Contact restaurant',
        key: 'label.OSS_CONTACT_RESTAURANT',
        type: 'label',
        value: 'OSS_CONTACT_RESTAURANT',
      },
      {
        defaultText: 'Spot Number',
        key: 'label.OSS_SPOT_NUMBER',
        type: 'label',
        value: 'OSS_SPOT_NUMBER',
      },
      {
        defaultText: 'Vehicle description',
        key: 'label.OSS_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'OSS_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Order summary',
        key: 'label.OSS_ORDER_SUMMARY',
        type: 'label',
        value: 'OSS_ORDER_SUMMARY',
      },
      {
        defaultText: 'Items',
        key: 'label.OSS_ITEMS',
        type: 'label',
        value: 'OSS_ITEMS',
      },
      {
        defaultText: 'Sub Total',
        key: 'label.OSS_SUB_TOTAL',
        type: 'label',
        value: 'OSS_SUB_TOTAL',
      },
      {
        defaultText: 'Tax',
        key: 'label.OSS_TAX',
        type: 'label',
        value: 'OSS_TAX',
      },
      {
        defaultText: 'Tip',
        key: 'label.OSS_TIP',
        type: 'label',
        value: 'OSS_TIP',
      },
      {
        defaultText: 'TOTAL',
        key: 'label.OSS_TOTAL_DUE',
        type: 'label',
        value: 'OSS_TOTAL_DUE',
      },
      {
        defaultText: 'View Receipt',
        key: 'label.OSS_VIEW_RECEIPT',
        type: 'label',
        value: 'OSS_VIEW_RECEIPT',
      },
      {
        defaultText: 'Choose a receipt option',
        key: 'label.OSS_CHOOSE_RECEIPT_OPTION',
        type: 'label',
        value: 'OSS_CHOOSE_RECEIPT_OPTION',
      },
      {
        defaultText: 'SMS',
        key: 'label.OSS_SMS',
        type: 'label',
        value: 'OSS_SMS',
      },
      {
        defaultText: 'Email',
        key: 'label.OSS_EMAIL',
        type: 'label',
        value: 'OSS_EMAIL',
      },
      {
        defaultText: 'Show order summary',
        key: 'label.OSS_SHOW_ORDER_SUMMARY',
        type: 'label',
        value: 'OSS_SHOW_ORDER_SUMMARY',
      },
      {
        defaultText: 'Hide order summary',
        key: 'label.OSS_HIDE_ORDER_SUMMARY',
        type: 'label',
        value: 'OSS_HIDE_ORDER_SUMMARY',
      },
      {
        defaultText: 'Please Enter Your Mobile Number',
        key: 'label.OSS_MOBILE_OPTIN_TITLE',
        type: 'label',
        value: 'OSS_MOBILE_OPTIN_TITLE',
      },
      {
        defaultText: 'Close',
        key: 'label.OSS_CLOSE_OPTIN',
        type: 'label',
        value: 'OSS_CLOSE_OPTIN',
      },
      {
        defaultText: 'Submit',
        key: 'label.OSS_OPTIN_SUBMIT',
        type: 'label',
        value: 'OSS_OPTIN_SUBMIT',
      },
      {
        defaultText: 'Please Enter Your Email Address',
        key: 'label.OSS_SMS_OPTIN',
        type: 'label',
        value: 'OSS_SMS_OPTIN',
      },
      {
        defaultText: 'Please enter a valid email',
        key: 'customMessage.OSS_ERR_INVALID_EMAIL',
        type: 'customMessage',
        value: 'OSS_ERR_INVALID_EMAIL',
      },
      {
        defaultText: 'Invalid Country Code',
        key: 'customMessage.OSS_ERR_INVALID_COUNTRY_CODE',
        type: 'customMessage',
        value: 'OSS_ERR_INVALID_COUNTRY_CODE',
      },
      {
        defaultText: 'Please enter Country Code',
        key: 'customMessage.OSS_ERR_REQ_COUNTRY_CODE',
        type: 'customMessage',
        value: 'OSS_ERR_REQ_COUNTRY_CODE',
      },
      {
        defaultText: 'Thank You!',
        key: 'customMessage.OSS_THANK_YOU',
        type: 'customMessage',
        value: 'OSS_THANK_YOU',
      },
      {
        defaultText: 'Your order is confirmed',
        key: 'customMessage.OSS_YOUR_ORDER_CONFIRMED',
        type: 'customMessage',
        value: 'OSS_YOUR_ORDER_CONFIRMED',
      },
      {
        defaultText: 'We have accepted your order, and we are getting it ready. Please check this page for more updates about your order.',
        key: 'customMessage.OSS_ACCEPTED_YOUR_ORDER_MSG',
        type: 'customMessage',
        value: 'OSS_ACCEPTED_YOUR_ORDER_MSG',
      },
      {
        defaultText: 'Your curbside order is from store',
        key: 'customMessage.OSS_CURBSIDE_ORDER_FROM_TITLE',
        type: 'customMessage',
        value: 'OSS_CURBSIDE_ORDER_FROM_TITLE',
      },
      {
        defaultText: 'Your pickup order is from store',
        key: 'customMessage.OSS_PICK_UP_ORDER_FROM_TITLE',
        type: 'customMessage',
        value: 'OSS_PICK_UP_ORDER_FROM_TITLE',
      },
      {
        defaultText: 'Your delivery order is from store',
        key: 'customMessage.OSS_DELIVERY_ORDER_FROM_TITLE',
        type: 'customMessage',
        value: 'OSS_DELIVERY_ORDER_FROM_TITLE',
      },
      {
        defaultText: 'Your walkup order is from store',
        key: 'customMessage.OSS_WALK_UP_ORDER_FROM_TITLE',
        type: 'customMessage',
        value: 'OSS_WALK_UP_ORDER_FROM_TITLE',
      },
      {
        defaultText: 'Your curbside order will be ready at',
        key: 'customMessage.OSS_CURBSIDE_ORDER_TO_TITLE',
        type: 'customMessage',
        value: 'OSS_CURBSIDE_ORDER_TO_TITLE',
      },
      {
        defaultText: 'Your pickup order will be ready at',
        key: 'customMessage.OSS_PICK_UP_ORDER_TO_TITLE',
        type: 'customMessage',
        value: 'OSS_PICK_UP_ORDER_TO_TITLE',
      },
      {
        defaultText: 'Your delivery order will be ready at',
        key: 'customMessage.OSS_DELIVERY_ORDER_TO_TITLE',
        type: 'customMessage',
        value: 'OSS_DELIVERY_ORDER_TO_TITLE',
      },
      {
        defaultText: 'Your walkup order will be ready at',
        key: 'customMessage.OSS_WALK_UP_ORDER_TO_TITLE',
        type: 'customMessage',
        value: 'OSS_WALK_UP_ORDER_TO_TITLE',
      },
      {
        defaultText: 'Paid with {{cardType}} card {{cardNumber}} (e.g: Paid with master card xx5486)',
        key: 'customMessage.OSS_PAID_WITH_CARD',
        type: 'customMessage',
        value: 'OSS_PAID_WITH_CARD',
      },
      {
        defaultText: 'Please enter an email',
        key: 'customMessage.OSS_ERR_EMAIL',
        type: 'customMessage',
        value: 'OSS_ERR_EMAIL',
      },
      {
        defaultText: 'Invalid Mobile Number',
        key: 'customMessage.OSS_ERR_MOBILE',
        type: 'customMessage',
        value: 'OSS_ERR_MOBILE',
      },
      {
        defaultText: 'Estimated points earned',
        key: 'label.OSS_ESTIMATED_POINTS_EARNED',
        type: 'label',
        value: 'OSS_ESTIMATED_POINTS_EARNED',
      },
      {
        defaultText: 'Please visit us again!',
        key: 'customMessage.OSS_PLEASE_VISIT_US_AGAIN',
        type: 'customMessage',
        value: 'OSS_PLEASE_VISIT_US_AGAIN',
      },
      {
        defaultText: 'We look forward to see you again!',
        key: 'customMessage.OSS_LOOKING_FORWARD_TO_SEEING_YOU_SOON',
        type: 'customMessage',
        value: 'OSS_LOOKING_FORWARD_TO_SEEING_YOU_SOON',
      },
    ]
  },
  PaymentOptinScreen: {
    screenLabel: 'PAYMENT - OPTIN SCREEN',
    image: [PaymentOptinScreen],
    customLabels: [
      {
        defaultText: 'Email Address',
        key: 'label.POS_EMAIL_ADDRESS',
        type: 'label',
        value: 'POS_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.POS_PHONE_NUMBER',
        type: 'label',
        value: 'POS_PHONE_NUMBER',
      },
      {
        defaultText: 'Save',
        key: 'label.POS_OPTIN_SAVE',
        type: 'label',
        value: 'POS_OPTIN_SAVE',
      },
      {
        defaultText: 'Skip Opt In',
        key: 'label.POS_SKIP_OPT_IN',
        type: 'label',
        value: 'POS_SKIP_OPT_IN',
      },
      {
        defaultText: 'Opt in to recieve communication',
        key: 'customMessage.POS_OPT_INTO_RECIEVE_COMMUNICATION',
        type: 'customMessage',
        value: 'POS_OPT_INTO_RECIEVE_COMMUNICATION',
      },
      {
        defaultText: 'Please enter valid email address',
        key: 'customMessage.POS_ERR_VALID_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'POS_ERR_VALID_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Please enter valid phone number',
        key: 'customMessage.POS_ERR_VALID_PHONE_NUMBER',
        type: 'customMessage',
        value: 'POS_ERR_VALID_PHONE_NUMBER',
      },
    ]
  },
  PaymentOptionsScreen: {
    screenLabel: 'Payment - Options Screen',
    image: [PaymentOptionsScreen],
    customLabels: [
      {
        defaultText: 'Add credit card',
        key: 'label.OPO_ADD_CREDIT_CARD',
        type: 'label',
        value: 'OPO_ADD_CREDIT_CARD',
      },
      {
        defaultText: 'ADD',
        key: 'label.OPO_ADD',
        type: 'label',
        value: 'OPO_ADD',
      },
      {
        defaultText: 'CLICK TO PAY',
        key: 'label.OPO_CLICK_TO_PAY',
        type: 'label',
        value: 'OPO_CLICK_TO_PAY',
      },
      {
        defaultText: 'Payment Options',
        key: 'label.OPO_PAYMENT_OPTIONS',
        type: 'label',
        value: 'OPO_PAYMENT_OPTIONS',
      },
      {
        defaultText: 'Update Account',
        key: 'label.OPO_UPDATE_ACCOUNT',
        type: 'label',
        value: 'OPO_UPDATE_ACCOUNT',
      },
      {
        defaultText: 'Hold Amount',
        key: 'label.OPO_HOLD',
        type: 'label',
        value: 'OPO_HOLD',
      },
      {
        defaultText: 'Hold with',
        key: 'label.OPO_HOLD_WITH',
        type: 'label',
        value: 'OPO_HOLD_WITH',
      },
      {
        defaultText: 'I Agree',
        key: 'label.OPO_I_AGREE',
        type: 'label',
        value: 'OPO_I_AGREE',
      },
      {
        defaultText: 'Confirm Card',
        key: 'label.OPO_CONFIRM_CARD',
        type: 'label',
        value: 'OPO_CONFIRM_CARD',
      },
      {
        defaultText: 'This location requires a credit card hold before placing an order. You must settle your tab for the final check amount before departing this establishment.',
        key: 'customMessage.OPO_PRE_AUTH_LOCATION_CREDIT_CARD',
        type: 'customMessage',
        value: 'OPO_PRE_AUTH_LOCATION_CREDIT_CARD',
      },
      {
        defaultText: 'A hold will be placed on your card. I agree to settle my tab for the final check amount before departing this establishment',
        key: 'customMessage.OPO_PRE_AUTH_HOLD',
        type: 'customMessage',
        value: 'OPO_PRE_AUTH_HOLD',
      },
    ]
  },
  PaymentLoyaltyScreen: {
    screenLabel: 'Payment - Loyalty Screen',
    image: [PaymentLoyaltyScreen],
    customLabels: [
      {
        defaultText: 'Offers',
        key: 'label.LTS_OFFERS',
        type: 'label',
        value: 'LTS_OFFERS',
      },
      {
        defaultText: 'Login or Register for Rewards',
        key: 'label.LTS_LOYALTY_LOGIN',
        type: 'label',
        value: 'LTS_LOYALTY_LOGIN',
      },
      {
        defaultText: 'View Loyalty Offers',
        key: 'label.LTS_VIEW_LOYALTY_OFFER',
        type: 'label',
        value: 'LTS_VIEW_LOYALTY_OFFER',
      },
      {
        defaultText: 'Already a member',
        key: 'label.LTS_LOYALTY_ALREADY_MEMBER',
        type: 'label',
        value: 'LTS_LOYALTY_ALREADY_MEMBER',
      },
      {
        defaultText: 'Join now',
        key: 'label.LTS_LOYALTY_JOIN_NOW',
        type: 'label',
        value: 'LTS_LOYALTY_JOIN_NOW',
      },
      {
        defaultText: 'Account Number',
        key: 'label.LTS_LOYALTY_ACC_NUMBER',
        type: 'label',
        value: 'LTS_LOYALTY_ACC_NUMBER',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.LTS_LOYALTY_PHONE_NUMBER',
        type: 'label',
        value: 'LTS_LOYALTY_PHONE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.LTS_LOYALTY_EMAIL_ADDRESS',
        type: 'label',
        value: 'LTS_LOYALTY_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Sign In',
        key: 'label.LTS_SIGN_IN',
        type: 'label',
        value: 'LTS_SIGN_IN',
      },
      {
        defaultText: 'Sign up',
        key: 'label.LTS_SIGN_UP',
        type: 'label',
        value: 'LTS_SIGN_UP',
      },
      {
        defaultText: 'Enter Account Number',
        key: 'label.LTS_ENTER_ACC_NUMBER',
        type: 'label',
        value: 'LTS_ENTER_ACC_NUMBER',
      },
      {
        defaultText: 'Enter Your Phone Number',
        key: 'label.LTS_ENTER_PHONE_NUMBER',
        type: 'label',
        value: 'LTS_ENTER_PHONE_NUMBER',
      },
      {
        defaultText: 'Enter Email Address',
        key: 'label.LTS_ENTER_EMAIL_ADDRESS',
        type: 'label',
        value: 'LTS_ENTER_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Account',
        key: 'label.LTS_LOYALTY_ACCOUNT_NUMBER',
        type: 'label',
        value: 'LTS_LOYALTY_ACCOUNT_NUMBER',
      },
      {
        defaultText: 'Offers available',
        key: 'label.LTS_LOYALTY_SELECT_AN_OFFER',
        type: 'label',
        value: 'LTS_LOYALTY_SELECT_AN_OFFER',
      },
      {
        defaultText: 'No offers currently available',
        key: 'label.LTS_NO_OFFERS_AVALIABLE',
        type: 'label',
        value: 'LTS_NO_OFFERS_AVALIABLE',
      },
      {
        defaultText: 'Skip Offer',
        key: 'label.LTS_LOYALTY_SKIP_OFFER',
        type: 'label',
        value: 'LTS_LOYALTY_SKIP_OFFER',
      },
      {
        defaultText: 'Apply Offer',
        key: 'label.LTS_LOYALTY_APPLY_OFFER',
        type: 'label',
        value: 'LTS_LOYALTY_APPLY_OFFER',
      },
      {
        defaultText: 'YES',
        key: 'label.LTS_YES',
        type: 'label',
        value: 'LTS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.LTS_NO',
        type: 'label',
        value: 'LTS_NO',
      },
      {
        defaultText: 'Value',
        key: 'label.LTS_LOYALTY_VALUE',
        type: 'label',
        value: 'LTS_LOYALTY_VALUE',
      },
      {
        defaultText: 'Points',
        key: 'label.LTS_POINTS_LABEL_TEXT',
        type: 'label',
        value: 'LTS_POINTS_LABEL_TEXT',
      },
      {
        defaultText: 'Would you like to join rewards program?',
        key: 'customMessage.LTS_LOYALTY_REWARDS_MESSAGE',
        type: 'customMessage',
        value: 'LTS_LOYALTY_REWARDS_MESSAGE',
      },
      {
        defaultText: 'How would you like to look up your account?',
        key: 'customMessage.LTS_LOYALTY_LOOKUP_ACCOUNT',
        type: 'customMessage',
        value: 'LTS_LOYALTY_LOOKUP_ACCOUNT',
      },
      {
        defaultText: 'How would you like to Signup your account?',
        key: 'customMessage.LTS_LOYALTY_LOOKUP_JOIN_NOW',
        type: 'customMessage',
        value: 'LTS_LOYALTY_LOOKUP_JOIN_NOW',
      },
      {
        defaultText: 'Do not have a loyalty account?',
        key: 'customMessage.LTS_NOT_HAVE_LOYALTY_ACCOUNT',
        type: 'customMessage',
        value: 'LTS_NOT_HAVE_LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Already have an account?',
        key: 'customMessage.LTS_ALREADY_HAVE_LOYALTY_ACCOUNT',
        type: 'customMessage',
        value: 'LTS_ALREADY_HAVE_LOYALTY_ACCOUNT',
      },
      {
        defaultText: 'Click here to',
        key: 'customMessage.LTS_CLICK_HERE_TO',
        type: 'customMessage',
        value: 'LTS_CLICK_HERE_TO',
      },
      {
        defaultText: 'Are you sure, You want to delete Loyalty?',
        key: 'customMessage.LTS_LOYALTY_WANT_TO_DELETE',
        type: 'customMessage',
        value: 'LTS_LOYALTY_WANT_TO_DELETE',
      },
      {
        defaultText: 'Are you sure, You want to delete this Offer?',
        key: 'customMessage.LTS_OFFER_WANT_TO_DELETE',
        type: 'customMessage',
        value: 'LTS_OFFER_WANT_TO_DELETE',
      },
      {
        defaultText: 'Invalid Account Number',
        key: 'customMessage.LTS_INVALID_ACCOUNT_NUMBER',
        type: 'customMessage',
        value: 'LTS_INVALID_ACCOUNT_NUMBER',
      },
      {
        defaultText: 'Account number must be at least 4 characters',
        key: 'customMessage.LTS_ACCOUNT_NUMBER_MIN_LENGTH',
        type: 'customMessage',
        value: 'LTS_ACCOUNT_NUMBER_MIN_LENGTH',
      },
      {
        defaultText: 'Mobile number must be exactly 10 numbers',
        key: 'customMessage.LTS_MOBILE_NUMBER_MESSAGE',
        type: 'customMessage',
        value: 'LTS_MOBILE_NUMBER_MESSAGE',
      },
      {
        defaultText: 'Please enter Country Code',
        key: 'customMessage.LTS_ENTER_COUNTRY_CODE_ERROR',
        type: 'customMessage',
        value: 'LTS_ENTER_COUNTRY_CODE_ERROR',
      },
      {
        defaultText: 'Please enter an email',
        key: 'customMessage.LTS_ENTER_EMAIL_ERROR',
        type: 'customMessage',
        value: 'LTS_ENTER_EMAIL_ERROR',
      },
      {
        defaultText: 'Please enter a valid email',
        key: 'customMessage.LTS_ENTER_VALID_EMAIL',
        type: 'customMessage',
        value: 'LTS_ENTER_VALID_EMAIL',
      },
    ]
  },
  TextToCheckInLandingScreen: {
    screenLabel: 'Text to check In screen - Landing screen',
    image: [TextToCheckInLandingScreen],
    customLabels: [
      {
        defaultText: 'Spot Number',
        key: 'label.TCLS_SPOT_NUMBER',
        type: 'label',
        value: 'TCLS_SPOT_NUMBER',
      },
      {
        defaultText: 'Vehicle Type',
        key: 'label.TCLS_VEHICLE_TYPE',
        type: 'label',
        value: 'TCLS_VEHICLE_TYPE',
      },
      {
        defaultText: 'Vehicle Color',
        key: 'label.TCLS_VEHICLE_COLOR',
        type: 'label',
        value: 'TCLS_VEHICLE_COLOR',
      },
      {
        defaultText: 'Confirm',
        key: 'label.TCLS_CONFIRM',
        type: 'label',
        value: 'TCLS_CONFIRM',
      },
      {
        defaultText: 'Please click the button below when you are at the store location.',
        key: 'customMessage.TCLS_CLICK_THE_BUTTON',
        type: 'customMessage',
        value: 'TCLS_CLICK_THE_BUTTON',
      },
      {
        defaultText: 'Please enter your spot number',
        key: 'customMessage.TCLS_ENTER_SPOT_NUMBER',
        type: 'customMessage',
        value: 'TCLS_ENTER_SPOT_NUMBER',
      },
      {
        defaultText: 'VehicleType is required',
        key: 'customMessage.TCLS_REQUIRED_VEHICLE_TYPE',
        type: 'customMessage',
        value: 'TCLS_REQUIRED_VEHICLE_TYPE',
      },
      {
        defaultText: 'VehicleColor is required',
        key: 'customMessage.TCLS_REQUIRED_VEHICLE_COLOR',
        type: 'customMessage',
        value: 'TCLS_REQUIRED_VEHICLE_COLOR',
      },
      {
        defaultText: 'Select Vehicle Type',
        key: 'label.TCLS_SELECT_VEHICLE_TYPE',
        type: 'label',
        value: 'TCLS_SELECT_VEHICLE_TYPE',
      },
      {
        defaultText: 'Select Vehicle Color',
        key: 'label.TCLS_SELECT_VEHICLE_COLOR',
        type: 'label',
        value: 'TCLS_SELECT_VEHICLE_COLOR',
      },
    ]
  },
  MyCheckScreen: {
    screenLabel: 'My Check Screen',
    image: [MyCheckScreen],
    customLabels: [
      {
        defaultText: 'My Check',
        key: 'label.MCS_MY_CHECK',
        type: 'label',
        value: 'MCS_MY_CHECK',
      },
      {
        defaultText: 'Reorder',
        key: 'label.MCS_REORDER',
        type: 'label',
        value: 'MCS_REORDER',
      },
      {
        defaultText: 'Click To Pay',
        key: 'label.MCS_CLICK_TO_PAY',
        type: 'label',
        value: 'MCS_CLICK_TO_PAY',
      },
      {
        defaultText: 'No checks are available',
        key: 'customMessage.MCS_NO_CHECKS_AVAILABLE',
        type: 'customMessage',
        value: 'MCS_NO_CHECKS_AVAILABLE',
      },
    ]
  },
  RefillScreen: {
    screenLabel: 'Refill  Screen',
    image: [RefillScreen],
    customLabels: [
      {
        defaultText: 'Add items to refill',
        key: 'label.RFS_ADD_ITEMS_TO_REFILL',
        type: 'label',
        value: 'RFS_ADD_ITEMS_TO_REFILL',
      },
      {
        defaultText: 'Add',
        key: 'label.RFS_ADD',
        type: 'label',
        value: 'RFS_ADD',
      },
      {
        defaultText: 'Add to Cart',
        key: 'label.RFS_ADD_TO_CARTS',
        type: 'label',
        value: 'RFS_ADD_TO_CARTS',
      },
      {
        defaultText: 'Remove',
        key: 'label.RFS_REFILL_REMOVE',
        type: 'label',
        value: 'RFS_REFILL_REMOVE',
      },
      {
        defaultText: 'Sorry, your order does not contain any items that are eligible for a refill',
        key: 'customMessage.RFS_REFILL_EMPTY_CART_MSG',
        type: 'customMessage',
        value: 'RFS_REFILL_EMPTY_CART_MSG',
      },
    ]
  },
  SplitEvenlyScreen: {
    screenLabel: 'Split - Evenly Screen',
    image: [SplitEvenlyScreen],
    customLabels: [
      {
        defaultText: 'Total due',
        key: 'label.SES_TOTAL_DUE',
        type: 'label',
        value: 'SES_TOTAL_DUE',
      },
      {
        defaultText: 'Confirm Split',
        key: 'label.SES_SPLIT',
        type: 'label',
        value: 'SES_SPLIT',
      },
      {
        defaultText: 'Select how many people will split this check',
        key: 'customMessage.SES_HOW_MANY_PPL',
        type: 'customMessage',
        value: 'SES_HOW_MANY_PPL',
      },
      {
        defaultText: 'The amount per person',
        key: 'customMessage.SES_PER_PERSON',
        type: 'customMessage',
        value: 'SES_PER_PERSON',
      }
    ]
  },
  OrderConfirmationScreen: {
    screenLabel: 'Order Confirmation Screen',
    image: [OrderConfirmationScreen],
    customLabels: [
      {
        defaultText: 'Next',
        key: 'label.OC_NEXT',
        type: 'label',
        value: 'OC_NEXT',
      },
      {
        defaultText: 'Your order has been placed.',
        key: 'customMessage.OC_YOUR_ORDER_HAS_BEEN_PLACED',
        type: 'customMessage',
        value: 'OC_YOUR_ORDER_HAS_BEEN_PLACED',
      },
    ]
  },
  TextToCheckInConfirmationScreen: {
    screenLabel: 'Text to check In screen - Confirmation Screen',
    image: [TextToCheckInConfirmationScreen],
    customLabels: [
      {
        defaultText: 'Your Check In Request has been Received!',
        key: 'customMessage.TCCS_CHECK_REQUEST_RECEIVED',
        type: 'customMessage',
        value: 'TCCS_CHECK_REQUEST_RECEIVED',
      },
      {
        defaultText: 'Change',
        key: 'label.TCCS_CHANGE',
        type: 'label',
        value: 'TCCS_CHANGE',
      },
    ]
  },
  CheckNumberLandingScreen: {
    screenLabel: 'Check Number Landing Screen',
    image: [CheckNumberLandingScreen],
    customLabels: [
      {
        defaultText: 'Check Number',
        key: 'label.CNS_CHECK_NUMBER',
        type: 'label',
        value: 'CNS_CHECK_NUMBER',
      },
      {
        defaultText: 'Skip check in',
        key: 'label.CNS_SKIP_CHECK_IN',
        type: 'label',
        value: 'CNS_SKIP_CHECK_IN',
      },
      {
        defaultText: 'Check In',
        key: 'label.CNS_CHECK_NUMBER_NEXT',
        type: 'label',
        value: 'CNS_CHECK_NUMBER_NEXT',
      },
      {
        defaultText: 'OK',
        key: 'label.CNS_OK',
        type: 'label',
        value: 'CNS_OK',
      },
      {
        defaultText: 'Cant find the check number? View sample',
        key: 'customMessage.CNS_CHECK_IN_MESSAGE',
        type: 'customMessage',
        value: 'CNS_CHECK_IN_MESSAGE',
      },
      {
        defaultText: 'Enter check number below',
        key: 'customMessage.CNS_ENTER_CHECK_NUMBER',
        type: 'customMessage',
        value: 'CNS_ENTER_CHECK_NUMBER',
      },
      {
        defaultText: 'Welcome to',
        key: 'customMessage.CNS_CHECK_IN_WELCOME_TO',
        type: 'customMessage',
        value: 'CNS_CHECK_IN_WELCOME_TO',
      },
      {
        defaultText: 'Check Number is Required',
        key: 'customMessage.CNS_ERR_CHECK_IN_REQUIRED',
        type: 'customMessage',
        value: 'CNS_ERR_CHECK_IN_REQUIRED',
      },
    ]
  },
  HostGuestLandingScreen: {
    screenLabel: 'Host Guest Landing Screen',
    image: [HostGuestLandingScreen],
    customLabels: [
      {
        defaultText: 'Paying for myself',
        key: 'label.HGM_PAYING_FOR_MYSELF',
        type: 'label',
        value: 'HGM_PAYING_FOR_MYSELF',
      },
      {
        defaultText: 'Paying for myself + My party',
        key: 'label.HGM_PAYING_FOR_MYSELF_AND_OTHERS',
        type: 'label',
        value: 'HGM_PAYING_FOR_MYSELF_AND_OTHERS',
      },
      {
        defaultText: 'Someone else is paying my tab',
        key: 'label.HGM_SOMEONE_ELSE_IS_PAYING',
        type: 'label',
        value: 'HGM_SOMEONE_ELSE_IS_PAYING',
      },
      {
        defaultText: 'Continue',
        key: 'label.HGM_CONTINUE',
        type: 'label',
        value: 'HGM_CONTINUE',
      },
      {
        defaultText: 'Before placing the order, Please choose from one of the following',
        key: 'customMessage.HGM_CHECK_JOIN_ORDERING_EXP_MSG',
        type: 'customMessage',
        value: 'HGM_CHECK_JOIN_ORDERING_EXP_MSG',
      },
      {
        defaultText: 'Please request a QR from your host to join this table.',
        key: 'customMessage.HGM_PLEASE_REQUEST_QR_PARTY_HOST',
        type: 'customMessage',
        value: 'HGM_PLEASE_REQUEST_QR_PARTY_HOST',
      },
      {
        defaultText: 'Want to invite over SMS? Copy the link below and send it using your SMS app',
        key: 'customMessage.HGM_WANT_TO_INVITE_OVER_SMS',
        type: 'customMessage',
        value: 'HGM_WANT_TO_INVITE_OVER_SMS',
      },
      {
        defaultText: 'Please present this QR code to any member of your party who would like to join this check',
        key: 'customMessage.HGM_PLEASE_PRESENT_THIS_QR',
        type: 'customMessage',
        value: 'HGM_PLEASE_PRESENT_THIS_QR',
      },
    ]
  },
  TableOrderPaymentOptionsScreen: {
    screenLabel: 'Table Payment Options',
    image: [TableOrderPaymentOptionsScreen],
    customLabels: [
      {
        defaultText: 'Payment Options',
        key: 'label.TPO_PAYMENT_OPTIONS',
        type: 'label',
        value: 'TPO_PAYMENT_OPTIONS',
      },
      {
        defaultText: 'Split Evenly',
        key: 'label.TPO_SPLIT_EVENLY',
        type: 'label',
        value: 'TPO_SPLIT_EVENLY',
      },
      {
        defaultText: 'Pay Remaining Balance',
        key: 'label.TPO_PAY_REMAINING_BALANCE',
        type: 'label',
        value: 'TPO_PAY_REMAINING_BALANCE',
      },
      {
        defaultText: 'Full Check',
        key: 'label.TPO_FULL_CHECK',
        type: 'label',
        value: 'TPO_FULL_CHECK',
      },
      {
        defaultText: 'Pay',
        key: 'label.TPO_PAY',
        type: 'label',
        value: 'TPO_PAY',
      },
    ]
  },
  CheckSelectionScreen: {
    screenLabel: 'Check Selection Screen',
    image: [CheckSelectionScreen],
    customLabels: [
      {
        defaultText: 'Continue',
        key: 'label.CSS_CONTINUE',
        type: 'label',
        value: 'CSS_CONTINUE',
      },
      {
        defaultText: 'more items',
        key: 'label.CSS_MORE_ITEMS',
        type: 'label',
        value: 'CSS_MORE_ITEMS',
      },
      {
        defaultText: 'show less',
        key: 'label.CSS_SHOW_LESS',
        type: 'label',
        value: 'CSS_SHOW_LESS',
      },
      {
        defaultText: 'Please select a check to pay',
        key: 'customMessage.CSS_SPLITED_CHECK_LIST_TITLE',
        type: 'customMessage',
        value: 'CSS_SPLITED_CHECK_LIST_TITLE',
      },
    ]
  },
  CheckPaidScreen: {
    screenLabel: 'Check Paid Screen',
    image: [CheckPaidScreen],
    customLabels: [
      {
        defaultText: 'Retry',
        key: 'label.CPS_RETRY',
        type: 'label',
        value: 'CPS_RETRY',
      },
      {
        defaultText: 'OK',
        key: 'label.CPS_OK',
        type: 'label',
        value: 'CPS_OK',
      },
      {
        defaultText: 'Please give us just a moment to load this check. Press Retry to try again.',
        key: 'customMessage.CPS_CHECK_PAID_MSG',
        type: 'customMessage',
        value: 'CPS_CHECK_PAID_MSG',
      },
      {
        defaultText: 'There is no new checks available',
        key: 'customMessage.CPS_NO_CHECKS',
        type: 'customMessage',
        value: 'CPS_NO_CHECKS',
      },
    ]
  },
  TableReassignment: {
    screenLabel: 'Table Reassignment',
    image: [TableReassignment],
    customLabels: [
      {
        defaultText: 'Retry',
        key: 'label.EMP_RETRY',
        type: 'label',
        value: 'EMP_RETRY',
      },
      {
        defaultText: 'Order cannot be placed because this section is not open. Please contact a server for assistance.',
        key: 'customMessage.EMP_TABLE_REASSIGNMENT_MSG',
        type: 'customMessage',
        value: 'EMP_TABLE_REASSIGNMENT_MSG',
      },
    ]
  },
  LoyaltyWallet: {
    screenLabel: 'Loyalty Wallet Screen',
    image: [LoyaltyWalletScreen],
    customLabels: [
      {
        defaultText: 'Available Offers',
        key: 'label.LWP_AVAILABLE_OFFER',
        type: 'label',
        value: 'LWP_AVAILABLE_OFFER',
      },
      {
        defaultText: 'Start date',
        key: 'label.LWP_START_DATE',
        type: 'label',
        value: 'LWP_START_DATE',
      },
      {
        defaultText: 'End date',
        key: 'label.LWP_END_DATE',
        type: 'label',
        value: 'LWP_END_DATE',
      },
      {
        defaultText: 'Value',
        key: 'label.LWP_VALUE',
        type: 'label',
        value: 'LWP_VALUE',
      },
    ]
  },
  StoreLocatorLandingScreen: {
    screenLabel: 'Store Locator Landing Screen',
    image: [StoreLocatorLandingScreen],
    customLabels: [
      {
        defaultText: 'Enter Location',
        key: 'label.SLLS_ENTER_LOCATION',
        type: 'label',
        value: 'SLLS_ENTER_LOCATION',
      },
      {
        defaultText: 'Search For Locations',
        key: 'label.SLLS_SEARCH_LOCATIONS',
        type: 'label',
        value: 'SLLS_SEARCH_LOCATIONS',
      },
      {
        defaultText: 'View all stores',
        key: 'label.SLLS_VIEW_ALL_STORES',
        type: 'label',
        value: 'SLLS_VIEW_ALL_STORES',
      },
      {
        defaultText: 'Find your nearest {{brandName}} for a great dining experience. Check locations, hours, and directions. We look forward to serving you!',
        key: 'customMessage.SLLS_LOCATOR_MESSAGE',
        type: 'customMessage',
        value: 'SLLS_LOCATOR_MESSAGE',
      },
      {
        defaultText: 'Please enter an address',
        key: 'customMessage.SLLS_ERR_ENTER_ADDRESS',
        type: 'customMessage',
        value: 'SLLS_ERR_ENTER_ADDRESS',
      },
    ]
  },
  StoreListingScreen: {
    screenLabel: 'Store Listing Screen',
    image: [StoreListingScreen],
    customLabels: [
      {
        defaultText: 'miles',
        key: 'label.SLS_MILES',
        type: 'label',
        value: 'SLS_MILES',
      },
      {
        defaultText: 'View location',
        key: 'label.SLS_VIEW_LOCATION',
        type: 'label',
        value: 'SLS_VIEW_LOCATION',
      },
      {
        defaultText: 'Hours',
        key: 'label.SLS_HOURS',
        type: 'label',
        value: 'SLS_HOURS',
      },
      {
        defaultText: 'Curbside',
        key: 'label.SLS_CURBSIDE',
        type: 'label',
        value: 'SLS_CURBSIDE',
      },
      {
        defaultText: 'Walkup',
        key: 'label.SLS_WALKUP',
        type: 'label',
        value: 'SLS_WALKUP',
      },
      {
        defaultText: 'Pickup',
        key: 'label.SLS_PICKUP',
        type: 'label',
        value: 'SLS_PICKUP',
      },
      {
        defaultText: 'Delivery',
        key: 'label.SLS_DELIVERY',
        type: 'label',
        value: 'SLS_DELIVERY',
      },
      {
        defaultText: 'Choose Order Method',
        key: 'customMessage.SLS_CHOOSE_ORDER_METHOD',
        type: 'customMessage',
        value: 'SLS_CHOOSE_ORDER_METHOD',
      },
      {
        defaultText: 'Sorry, online ordering is not supported at this location',
        key: 'customMessage.SLS_SORRY_ORDERING_IS_NOT_SUPPORTED_IN_THIS_LOCATION',
        type: 'customMessage',
        value: 'SLS_SORRY_ORDERING_IS_NOT_SUPPORTED_IN_THIS_LOCATION',
      },
    ]
  },
};
