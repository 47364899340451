import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 *
 * @returns authenicated components
 */
const PrivateRoute = () => {
  const isAuthenticated = window.localStorage.getItem('isUserLogin');
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/login' : '/login', }} />
  );
};

export default PrivateRoute;
