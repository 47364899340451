

import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
  Route
} from 'react-router-dom';
import { APP_CONFIG } from '@constants';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import withRouter from './withRouter';
import OnRouteChange from '@containers/common/OnRouteChange';
import PageLoader from '@components/common/PageLoader';
import NotFound from '@components/404NotFound/index';
import { lazyRetry } from './utility/helperFunction';
import Notification from '@components/common/notification/indexV4';


const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
        Sorry, unable to load the page
      </div>
    );
  }
  return null;
};

const OnRouteChangeWithRouter = withRouter(OnRouteChange);
const BasicLayout = () => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <OnRouteChangeWithRouter>
        <Notification />
        <Outlet />
      </OnRouteChangeWithRouter>
    </Suspense>
  );
};

const Login = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Login')),
));

const ManagerDashboard = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard')),
));

const ManagerLoginFailed = withRouter(lazy(
  () => lazyRetry(() => import('@components/storeManager/failureLoginPage')),
));

const StorePhone = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard/storeManagerPhone')),
));

const LoginWithApiKey = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Login/loginWithApiKey')),
));

const ForgotPassword = withRouter(lazy(
  () => lazyRetry(() => import('@containers/ForgotPassword')),
));

const V4Component = withRouter(lazy(
  () => lazyRetry(() => import('@containers/V4')),
));

const EmpApp = withRouter(lazy(
  () => lazyRetry(() => import('@containers/EmpApp')),
));

const PaymentRequest = withRouter(lazy(
  () => lazyRetry(() => import('@containers/managerDashboard/paymentRequest')),
));

const Receipt = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Receipt')),
));

const AppDownloadLink = withRouter(lazy(
  () => lazyRetry(() => import('@containers/appDownloadUrl')),
));

const OrderApproval = withRouter(lazy(
  () => lazyRetry(() => import('@containers/OrderApproval')),
));

const GiftCardReceipt = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Receipt/giftCardReceipt')),
));

const DisplayStoreOrder = withRouter(lazy(
  () => lazyRetry(() => import('@containers/DisplayStoreOrder')),
));

const ApiLogsDetails = withRouter(lazy(
  () => lazyRetry(() => import('@containers/Reports/ApiLogDetails')),
));

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route key={`${APP_CONFIG.BASE_URL}/`} path={`${APP_CONFIG.BASE_URL}/`} element={<BasicLayout />}>
      <Route path={`${APP_CONFIG.BASE_URL}/`} element={<PublicRoute />}>
        {/* Public routes */}
        <Route index key={`${APP_CONFIG.BASE_URL}/`} path={`${APP_CONFIG.BASE_URL}/`} element={<Login />} />
        <Route index key={`${APP_CONFIG.BASE_URL}/login`} path={`${APP_CONFIG.BASE_URL}/login`} element={<Login />} />
        <Route key={`${APP_CONFIG.BASE_URL}/manager/dashboard`} path={`${APP_CONFIG.BASE_URL}/manager/dashboard`} element={<ManagerDashboard />} />
        <Route key={`${APP_CONFIG.BASE_URL}/login-falied`} path={`${APP_CONFIG.BASE_URL}/login-falied`} element={<ManagerLoginFailed />} />
        <Route key={`${APP_CONFIG.BASE_URL}/store-manaager/phone`} path={`${APP_CONFIG.BASE_URL}/store-manaager/phone`} element={<StorePhone />} />
        <Route key={`${APP_CONFIG.BASE_URL}/store-manaager/text-to-pay`} path={`${APP_CONFIG.BASE_URL}/store-manaager/text-to-pay`} element={<PaymentRequest />} />
        <Route key={`${APP_CONFIG.BASE_URL}/token/apiKey/:apiKey`} path={`${APP_CONFIG.BASE_URL}/token/apiKey/:apiKey`} element={<LoginWithApiKey />} />
        <Route key={`${APP_CONFIG.BASE_URL}/forgotpassword`} path={`${APP_CONFIG.BASE_URL}/forgotpassword`} element={<ForgotPassword />} />
      </Route>
      <Route key={`${APP_CONFIG.BASE_URL}/download`} path={`${APP_CONFIG.BASE_URL}/download`} element={<AppDownloadLink />} />
      <Route key={`${APP_CONFIG.BASE_URL}/empApp/*`} path={`${APP_CONFIG.BASE_URL}/empApp/*`} element={<EmpApp />} />
      <Route key={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/:receiptType/display/:ticketId/:referenceCode`} element={<Receipt />} />
      <Route key={`${APP_CONFIG.BASE_URL}/apiLogsDetails/:apilogId/:requestDate`} path={`${APP_CONFIG.BASE_URL}/apiLogsDetails/:apilogId/:requestDate`} element={<ApiLogsDetails />} />
      <Route key={`${APP_CONFIG.BASE_URL}/order/display/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/order/display/:referenceCode`} element={<DisplayStoreOrder />} />
      <Route key={`${APP_CONFIG.BASE_URL}/orders/seat/approvalRequest/:checkHeaderId/:referenceCode/:seatId`} path={`${APP_CONFIG.BASE_URL}/orders/seat/approvalRequest/:checkHeaderId/:referenceCode/:seatId`} element={<OrderApproval />} />
      <Route key={`${APP_CONFIG.BASE_URL}/:receiptType/giftCard/display/:ticketId/:referenceCode`} path={`${APP_CONFIG.BASE_URL}/:receiptType/giftCard/display/:ticketId/:referenceCode`} element={<GiftCardReceipt />} />
      <Route path={`${APP_CONFIG.BASE_URL}/`} element={<PrivateRoute />}>
        {/* Protected routes */}
        <Route path="*" element={<V4Component />} />
        {/* Add more protected routes here */}
      </Route>
      <Route key="*" path="*" element={<NotFound />} />
    </Route>

  </>
));
export default (
  <div className="container-fluid p-0">
    <RouterProvider router={router} />
  </div>
);
