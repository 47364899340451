import pako from 'pako';
import { APP_CONFIG, HISTORY_DATA } from '@constants';

export const getTime = (dateObj) => {
  const date = dateObj.split(':');
  return new Date().setHours(Number(date[0]), Number(date[1]), Number(date[2]) || 0);
};

export const getDate = (dateObj) => {
  const date = new Date(dateObj);
  return date;
};

export const getTimeWithDate = (dateObj) => {
  const date = dateObj.split(':');
  const timeInMileSecond = new Date().setHours(Number(date[0]), Number(date[1]), Number(date[2]) || 0);
  return new Date(timeInMileSecond);
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const decriptResponse = (base64Data) => {
  const strData = atob(base64Data);
  // Convert binary string to character-number array
  const charData = strData.split('').map((x) => {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  // Pako magic
  const data = pako.inflate(binData);
  // Convert gunzipped byteArray back to ascii string:
  // const strDataResult = String.fromCharCode.apply(null, new Uint16Array(data));
  return JSON.parse(new TextDecoder('utf-8').decode(data));
};

export const truncate = (n, len) => {
  const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
  let filename = n.replace(`.${ext}`, '');
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
  return `${filename}.${ext}`;
};

export const includev3 = (path) => {
  const v3Route = ['/v3'];
  if (path.includes(v3Route)) {
    return true;
  }
  return false;
};

export const getObjectValue = (cb, defaultValue) => {
  try {
    return cb();
  } catch (e) {
    return defaultValue;
  }
};

export const filterColorConversion = (data) => {
  const tolerance = 1;
  const invertRange = [0, 1];
  const invertStep = 0.1;
  const sepiaRange = [0, 1];
  const sepiaStep = 0.1;
  const saturateRange = [5, 100];
  const saturateStep = 5;
  const hueRotateRange = [0, 360];
  const hueRotateStep = 5;
  let possibleColors = [];
  // matrices taken from https://www.w3.org/TR/filter-effects/#feColorMatrixElement
  const sepiaMatrix = (s) => {
    return [
      (0.393 + 0.607 * (1 - s)), (0.769 - 0.769 * (1 - s)), (0.189 - 0.189 * (1 - s)),
      (0.349 - 0.349 * (1 - s)), (0.686 + 0.314 * (1 - s)), (0.168 - 0.168 * (1 - s)),
      (0.272 - 0.272 * (1 - s)), (0.534 - 0.534 * (1 - s)), (0.131 + 0.869 * (1 - s)),
    ];
  };
  const saturateMatrix = (s) => {
    return [
      /* eslint-disable-next-line */
      0.213+0.787*s, 0.715-0.715*s, 0.072-0.072*s,
      /* eslint-disable-next-line */
      0.213-0.213*s, 0.715+0.285*s, 0.072-0.072*s,
      /* eslint-disable-next-line */
      0.213-0.213*s, 0.715-0.715*s, 0.072+0.928*s,
    ];
  };
  const hueRotateMatrix = (d) => {
    const cos = Math.cos(d * Math.PI / 180);
    const sin = Math.sin(d * Math.PI / 180);
    /* eslint-disable-next-line */
    const a00 = 0.213 + cos*0.787 - sin*0.213;
    /* eslint-disable-next-line */
    const a01 = 0.715 - cos*0.715 - sin*0.715;
    /* eslint-disable-next-line */
    const a02 = 0.072 - cos*0.072 + sin*0.928;
    /* eslint-disable-next-line */
    const a10 = 0.213 - cos*0.213 + sin*0.143;
    /* eslint-disable-next-line */
    const a11 = 0.715 + cos*0.285 + sin*0.140;
    /* eslint-disable-next-line */
    const a12 = 0.072 - cos*0.072 - sin*0.283;
    /* eslint-disable-next-line */
    const a20 = 0.213 - cos*0.213 - sin*0.787;
    /* eslint-disable-next-line */
    const a21 = 0.715 - cos*0.715 + sin*0.715;
    /* eslint-disable-next-line */
    const a22 = 0.072 + cos*0.928 + sin*0.072;

    return [
      a00, a01, a02,
      a10, a11, a12,
      a20, a21, a22,
    ];
  };
  const clamp = (value) => {
    /* eslint-disable-next-line */
    return value > 255 ? 255 : value < 0 ? 0 : value;
  };

  const filter = (m, c) => {
    return [
      /* eslint-disable-next-line */
      clamp(m[0]*c[0] + m[1]*c[1] + m[2]*c[2]),
      /* eslint-disable-next-line */
      clamp(m[3]*c[0] + m[4]*c[1] + m[5]*c[2]),
      /* eslint-disable-next-line */
      clamp(m[6]*c[0] + m[7]*c[1] + m[8]*c[2]),
    ];
  };

  const invertBlack = (i) => {
    return [
      i * 255,
      i * 255,
      i * 255,
    ];
  };

  const generateColors = () => {
    possibleColors = [];

    let invert = invertRange[0];
    /* eslint-disable-next-line */
    for (invert; invert <= invertRange[1]; invert+=invertStep) {
      let sepia = sepiaRange[0];
      /* eslint-disable-next-line */
      for (sepia; sepia <= sepiaRange[1]; sepia+=sepiaStep) {
        /* eslint-disable-next-line */
        let saturate = saturateRange[0];
        /* eslint-disable-next-line */
        for (saturate; saturate <= saturateRange[1]; saturate+=saturateStep) {
          let hueRotate = hueRotateRange[0];
          /* eslint-disable-next-line */
          for (hueRotate; hueRotate <= hueRotateRange[1]; hueRotate+=hueRotateStep) {
            const invertColor = invertBlack(invert);
            const sepiaColor = filter(sepiaMatrix(sepia), invertColor);
            const saturateColor = filter(saturateMatrix(saturate), sepiaColor);
            const hueRotateColor = filter(hueRotateMatrix(hueRotate), saturateColor);

            const colorObject = {
              /* eslint-disable-next-line */
              filters: { invert, sepia, saturate, hueRotate },
              color: hueRotateColor
            };

            possibleColors.push(colorObject);
          }
        }
      }
    }

    return possibleColors;
  };

  const getFilters = (targetColor, localTolerance) => {
    possibleColors = generateColors();
    /* eslint-disable-next-line */
    for (let i = 0; i < possibleColors.length; i++) {
      const color = possibleColors[i].color;
      if (
        /* eslint-disable-next-line */
        Math.abs(color[0] - targetColor[0]) < localTolerance &&
        /* eslint-disable-next-line */
        Math.abs(color[1] - targetColor[1]) < localTolerance &&
        Math.abs(color[2] - targetColor[2]) < localTolerance
      ) {
        return possibleColors[i].filters;
      }
    }
    /* eslint-disable-next-line */
    localTolerance += tolerance;
    return getFilters(targetColor, localTolerance);
  };

  const getNewColor = (color) => {
    let targetColor = color.split(',');
    targetColor = [
      parseInt(targetColor[0], 10), // [R]
      parseInt(targetColor[1], 10), // [G]
      parseInt(targetColor[2], 10), // [B]
    ];
    const filters = getFilters(targetColor, tolerance);
    /* eslint-disable-next-line */
    const filtersCSS = 'invert('+Math.floor(filters.invert*100)+'%) '+ 'sepia('+Math.floor(filters.sepia*100)+'%) ' + 'saturate('+Math.floor(filters.saturate*100)+'%) ' + 'hue-rotate('+Math.floor(filters.hueRotate)+'deg);';
    return filtersCSS;
  };

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const x = result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
    return `${x.r},${x.g},${x.b}`;
  };
  return getNewColor(hexToRgb(data));
};

export const copyToClipboard = (data) => {
  const tmp = document.createElement('input');
  tmp.value = data;
  tmp.type = 'text';
  tmp.setAttribute('readonly', '');
  document.body.appendChild(tmp);
  tmp.select();
  document.execCommand('copy');
  document.body.removeChild(tmp);
};

export const getPascalCase = (string) => {
  return string.replace(/(\w)(\w*)/g, (g0, g1, g2) => { return g1.toUpperCase() + g2.toLowerCase(); });
};

export const handleUpdateNavigation = (historyData, matchData, url, data) => {
  const BASE_URL = APP_CONFIG.BASE_URL;
  let redirection = '';
  const pathData = historyData;
  // if (matchData && matchData.url && matchData.url.includes('/home')) { // it will check if url contains "/home"(code changes from history - location according to react 18)
  if (matchData && matchData.pathname && matchData.pathname.includes('/home')) { // it will check if url contains "/home"
    redirection = url !== '' ? `${BASE_URL}/home/${url}` : `${BASE_URL}/home`;
  } else if (url === '') { // if url === '' it will redirect to "/dashboard"
    redirection = `${BASE_URL}/dashboard`;
  } else {
    redirection = `${BASE_URL}/${url}`;
  }
  const historyObject = { pathname: redirection };
  pathData(historyObject.pathname, data && { state: { data } }); // code changes from history to navigate according to react 18
  return pathData;
};

// for redirecting the page after save or update to dashboard/home screen
export const handleRouteNavigation = (navigate, location) => {
  const currentPath = location.pathname;
  const foundMatch = HISTORY_DATA.find(item => currentPath.includes(item.link));
  if (foundMatch) handleUpdateNavigation(navigate, { url: currentPath }, foundMatch.backNavLink);
};

export const getCurrency = (countryCode) => {
  switch (countryCode) {
    case '1':
      return '$';
    case '44':
      return '£';
    default:
      return '';
  }
};

export const lazyRetry = (componentImport) => {
  return new Promise((resolve) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );

    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed || (hasRefreshed === 'false')) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
        return window.location.reload(); // refresh the page
      }
      return error;
      // reject(error);
    });
  });
};
