
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 *
 * @returns authenicated components
*/
const PublicRoute = () => {
  const isAuthenticated = !window.localStorage.getItem('isUserLogin');
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: window.location.href.indexOf('/empApp') !== -1 ? '/empApp/storeSelect' : '/dashboard' }} />
  );
};

export default PublicRoute;
